@use '../../fundation/index' as *;

/*  c-head01
----------------------------------- */
.c-head01{
  margin-bottom: 40px;
  .c-head01__icon{
    margin: 0 auto;
    width: 27px;
  }
  .c-head01__ttl{
    padding-top: 14px;
    @include font_title;
    font-size: font-size(26);
    text-align: center;
  }
  @include mq (large){
    margin-bottom: 80px;
    .c-head01__icon{
      width: 36px;
    }
    .c-head01__ttl{
      padding-top: 24px;
      font-size: font-size(40);
    }
  }
}

/*  c-head02
----------------------------------- */
.c-head02{
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $clr_border;
  .c-head02__ttl{
    position: relative;
    padding-left: 21px;
    @include font_title;
    font-size: font-size(22);
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 5px;
      height: 100%;
      top: 0;
      left: 0;
      background: $clr_primary;
    }
  }
  .c-head02__small{
    display: block;
    font-size: font-size(16);
  }
  @include mq (large){
    margin-bottom: 32px;
    padding-bottom: 18px;
    .c-head02__ttl{
      padding-left: 30px;
      font-size: font-size(28);
      &::before {
        width: 6px;
        top: 2px;
      }
    }
  }
}