@use '../../fundation/index' as *;

/*  p-service
----------------------------------- */
.p-service{
  padding-top: 80px;
  @include mq (large){
    padding-top: 90px;
  }
}

/*  p-service__intro
----------------------------------- */
.p-service__intro{
  padding-bottom: 80px;
  .p-service__intro-inner{
    @include inner;
  }
  .p-service__intro-contents{
    margin-top: 32px;
    padding: 8px;
    background: #fff;
    border: 1px solid $clr_border;
    border-radius: 10px;
  }
  .p-service__intro-contents-ttl{
    padding: 12px 24px;
    background: $clr_primary_light;
    border-radius: 10px;
    @include font_title;
    text-align: center;
    strong{
      color: $clr_primary;
    }
  }
  .p-service__intro-contents-img{
    padding: 16px;
  }
  @include mq (medium){
    .p-service__intro-contents-img{
      margin: 0 auto;
      width: 60%;
    }
  }
  @include mq (large){
    padding-bottom: 130px;
    .p-service__intro-contents{
      margin-top: 40px;
      padding: 16px 16px 0;
    }
    .p-service__intro-contents-ttl{
      padding: 16px;
      font-size: font-size(20);
    }
    .p-service__intro-contents-img{
      margin: 0;
      padding: 38px 64px;
      width: 100%;
    }
  }
}

/*  p-service-factoring
----------------------------------- */
.p-service-factoring{
  padding: 80px 0;
  background: $clr_bg;
  .p-service-factoring__inner{
    @include inner;
  }
  .p-service-factoring__contents{
    display: flex;
    flex-direction: column;
  }
  .p-service-factoring__contents-img{
    img{
      border-radius: 10px;
    }
  }
  .p-service-factoring__contents-txtArea{
    margin-top: 24px;
  }
  .p-service-factoring__contents-ttl{
    font-size: font-size(22);
    @include font_title;
  }
  .p-service-factoring__contents-txt{
    margin-top: 20px;
  }
  .p-service-factoring__merit{
    margin-top: 40px;
  }
  .p-service-factoring__merit-item{
    margin-top: 32px;
    padding: 16px;
    background: #fff;
    border-radius: 10px;
  }
  .p-service-factoring__merit-item-bg{
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: $clr_bg_light;
    border-radius: 10px;
  }
  .p-service-factoring__merit-item-child{
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $clr_border_light;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  .p-service-factoring__merit-child-num{
    margin-bottom: 5px;
    font-size: font-size(12);
    color: $clr_primary;
    font-weight: 500;
  }
  .p-service-factoring__merit-child-txt{
    font-size: font-size(16);
    @include font_title;
  }
  @include mq (large){
    padding: 130px 0;
    .p-service-factoring__contents{
      flex-direction: row-reverse;
      align-items: center;
    }
    .p-service-factoring__contents-img{
      width: 360px;
    }
    .p-service-factoring__contents-txtArea{
      margin-top: 0;
      margin-right: 50px;
      width: calc(100% - 410px);
    }
    .p-service-factoring__contents-ttl{
      font-size: font-size(28);
    }
    .p-service-factoring__contents-txt{
      margin-top: 40px;
    }
    .p-service-factoring__merit{
      margin-top: 80px;
    }
    .p-service-factoring__merit-item{
      margin-top: 30px;
    }
    .p-service-factoring__merit-item-bg{
      padding: 32px 70px;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 20px;
    }
    .p-service-factoring__merit-item-child{
      flex-direction: row;
      align-items: center;
      padding-top: 24px;
      padding-bottom: 24px;
      width: calc(50% - 10px);
      gap: 24px;
      &:nth-child(n+1) {
        padding-top: 0;
      }
      &:nth-child(n+3) {
        padding-top: 24px;
        padding-bottom: 0;
        border-bottom: none;
      }
      &:nth-child(n+5) {
        margin-top: 24px;
        border-top: 1px solid $clr_border_light;
      }
    }
    .p-service-factoring__merit-child-num{
      width: 50px;
      margin-bottom: 0;
    }
    .p-service-factoring__merit-child-txt{
      width: calc(100% - 50px);
      font-size: font-size(17);
      @include font_title;
    }
  }
  @include mq (full){
    .p-service-factoring__contents-img{
      width: 660px;
    }
    .p-service-factoring__contents-txtArea{
      margin-right: 110px;
      width: calc(100% - 770px);
    }
    .p-service-factoring__contents-ttl{
      font-size: font-size(32);
    }
    .p-service-factoring__merit-item-bg{
      padding: 32px 70px;
      column-gap: 40px;
    }
    .p-service-factoring__merit-item-child{
      width: calc(50% - 20px);
    }
  }
}

/*  p-service-feature
----------------------------------- */
.p-service-feature{
  padding: 80px 0;
  .p-service-feature__inner{
    @include inner;
  }
  .p-service-feature__contents-itemWrap{
    padding: 30px 0;
    &:first-child {
      padding: 0 0 30px 0;
    }
    &:last-child {
      padding: 30px 0 0 0;
    }
  }
  .p-service-feature__contents-item{
    display: flex;
    flex-direction: column;
    &.-orange{
      strong{
        color: $clr_primary;
      }
    }
    &.-green{
      strong{
        color: $clr_green;
      }
    }
    &.-blue{
      strong{
        color: $clr_blue;
      }
    }
  }
  .p-service-feature__contents-img{
    img{
      border-radius: 10px;
    }
  }
  .p-service-feature__contents-txtArea{
    margin-top: 20px;
  }
  .p-service-feature__contents-num{
    color: $clr_txt_gray;
    font-size: font-size(12);
  }
  .p-service-feature__contents-ttl{
    margin-top: 8px;
    font-size: font-size(22);
    @include font_title;
  }
  .p-service-feature__contents-txt{
    margin-top: 20px;
  }
  .p-service-feature__contact{
    margin-top: 32px;
    padding: 24px 16px;
    background: $clr_bg;
    border-radius: 10px;
  }
  .p-service-feature__contact-ttlArea{
    margin-bottom: 24px;
    padding: 0 16px;
  }
  .p-service-feature__contact-ttl{
    font-size: font-size(24);
    font-weight: 500;
    color: $clr_primary;
    text-align: center;
  }
  .p-service-feature__contact-txt{
    margin-top: 14px;
  }
  .p-index-flow__step{
    margin-top: 50px;
    gap: 36px;
  }
  .p-index-flow__step-item{
    position: relative;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 32px;
      height: 16px;
      bottom: -26px;
      right: 50%;
      translate: 50% 0%;
      background: url(../img/service/icon-step-arw.svg) no-repeat center /contain;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
  .p-index-flow__step-child-img{
    margin: 16px auto 0;
    width: 200px;
  }
  .p-service-feature__contents-item-child{
    margin-top: 40px;
  }
  @include mq (large){
    padding: 130px 0;
    .p-service-feature__contents-itemWrap{
      padding: 50px 0;
      &:first-child {
        padding: 0 0 50px 0;
      }
      &:last-child {
        padding: 50px 0 0 0;
      }
    }
    .p-service-feature__contents-item{
      margin-left: -50px;
      flex-direction: row;
      align-items: center;
      gap: 70px;
      &.-rev{
        margin-left: 0;
        margin-right: -50px;
        flex-direction: row-reverse;
      }
    }
    .p-service-feature__contents-img{
      width: 350px;
    }
    .p-service-feature__contents-txtArea{
      margin-top: 0;
      width: calc(100% - 420px);
    }
    .p-service-feature__contents-num{
      font-size: font-size(15);
    }
    .p-service-feature__contents-ttl{
      margin-top: 10px;
      font-size: font-size(28);
    }
    .p-service-feature__contents-txt{
      margin-top: 28px;
    }
    .p-service-feature__contact{
      margin-top: 80px;
      padding: 40px 20px;
    }
    .p-service-feature__contact-contents{
      grid-template-columns: 1fr;
    }
    .p-service-feature__contact-item{
      margin-top: 0;
      padding: 30px 20px !important;
    }
    .p-service-feature__contact-ttlArea{
      margin-bottom: 40px;
      padding: 0;
    }
    .p-service-feature__contact-ttl{
      font-size: font-size(40);
    }
    .p-service-feature__contact-txt{
      margin-top: 16px;
      text-align: center;
    }
    .p-index-flow__step{
      margin-top: 40px;
      gap: 40px;
    }
    .p-index-flow__step-item{
      &::after {
        width: 20px;
        height: 17px;
        bottom: 50%;
        right: -20px;
        rotate: -90deg;
      }
    }
    .p-index-flow__step-child-img{
      margin: 24px auto 0;
      width: 253px;
    }
    .p-service-feature__contents-item-child{
      margin-top: 80px;
    }
  }
  @media all and (width >= 1200px) {
    .p-service-feature__contact-contents{
      grid-template-columns: repeat(3,1fr);
    }
  }
  @include mq (full){
    .p-service-feature__contents-item{
      margin-left: -160px;
      gap: 110px;
      &.-rev{
        margin-right: -160px;
      }
    }
    .p-service-feature__contents-img{
      width: min(770px,100%);
    }
    .p-service-feature__contents-txtArea{
      width: calc(100% - 880px);
    }
    .p-service-feature__contents-ttl{
      font-size: font-size(32);
    }
    .p-service-feature__contents-txt{
      margin-top: 48px;
    }
    .p-service-feature__contact{
      padding: 60px 80px;
    }
    .p-service-feature__contact-item{
      padding: 45px 32px !important;
    }
  }
}

/*  p-service-faq
----------------------------------- */
.p-service-faq{
  padding: 80px 0;
  background: $clr_bg;
  .p-service-faq__inner{
    @include inner(920px);
  }
  .p-service-faq__head{
    margin-bottom: 24px;
  }
  .p-service-faq__txt{
    text-align: center;
  }
  .p-service-faq__contents{
    margin-top: 40px;
  }
  @include mq (large){
    padding: 130px 0;
    .p-service-faq__head{
      margin-bottom: 40px;
    }
    .p-service-faq__contents{
      margin-top: 80px;
    }
  }
}