@use '../../fundation/index' as *;

/*  p-index-hero
----------------------------------- */
.p-index-hero{
  .p-index-hero__contentsWrap{
    background: $clr_bg;
  }
  .p-index-hero__contents{
    padding: 50px 20px 0;

  }
  .p-index-hero__contents-ttl{
    @include font_title;
    text-align: center;
  }
  .p-index-hero__contents-ttl-small{
    display: block;
    font-size: font-size(15);
  }
  .p-index-hero__contents-ttl-main{
    font-size: font-size(36);
  }
  .p-index-hero__contents-btn{
    margin-top: 24px;
  }
  .p-index-hero__point{
    display: flex;
    margin-top: 32px;
    gap: 7px;
  }
  .p-index-hero__point-item{
    width: calc(33.33% - (14px/3));
  }
  @include mq (medium){
    .p-index-hero__point{
      justify-content: center;
      gap: 12px;
    }
    .p-index-hero__point-item{
      width: min(200px,100%);
    }
  }
  @include mq (large){
    .p-index-hero__contentsWrap{
      position: relative;
      margin: 0 50px;
    }
    .p-index-hero__contents-img{
      position: absolute;
      bottom: 0;
      right: -100px;
    }
    .p-index-hero__contents{
      position: relative;
      padding: 100px 0 100px 80px;
      z-index: 1;
    }
    .p-index-hero__contents-ttl-small{
      text-align: left;
      font-size: font-size(28);
    }
    .p-index-hero__contents-ttl-main{
      font-size: font-size(56);
      text-align: left;
    }
    .p-index-hero__contents-btn{
      margin-top: 40px;
    }
    .p-index-hero__point{
      margin-top: 64px;
      justify-content: start;
      gap: 20px;
    }
    .p-index-hero__point-item{
      width: 140px;
    }
  }
  @include mq (full){
    .p-index-hero__contentsWrap{
      margin: 70px 100px 0;
    }
    .p-index-hero__contents{
      padding: 124px 0 124px 160px;
    }
  }
}

/*  p-index-message
----------------------------------- */
.p-index-message{
  position: relative;
  padding: 80px 0 70px;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100vw;
    background: url(../img/common/img-deco.svg) no-repeat top center /contain;
    bottom: 1px;
    translate: 0 100%;
    aspect-ratio: 1920/120;
    z-index: 1;
  }
  .p-index-message__inner{
    @include inner;
  }
  .p-index-message__contents{
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .p-index-message__contents-img{
    margin: 0 auto;
    width: 255px;
  }
  .p-index-message__contents-txtArea{
    padding: 0 20px;
  }
  .p-index-message__contents-ttl{
    @include font_title;
    font-size: font-size(26);
  }
  .p-index-message__contents-ttl-small{
    display: block;
    padding-bottom: 8px;
    font-size: font-size(17);
  }
  .p-index-message__contents-txt{
    margin-top: 24px;
  }
  @include mq (large){
    padding: 120px 0 70px;
    .p-index-message__contents{
      flex-direction: row;
      align-items: center;
      gap: 90px;
    }
    .p-index-message__contents-img{
      margin: 0;
      width: 330px;
    }
    .p-index-message__contents-txtArea{
      padding: 0;
      width: calc(100% - 420px);
    }
    .p-index-message__contents-ttl{
      font-size: font-size(40);
    }
    .p-index-message__contents-ttl-small{
      padding-bottom: 12px;
      font-size: font-size(22);
    }
    .p-index-message__contents-txt{
      margin-top: 40px;
    }
  }
  @include mq (full){
    .p-index-message__contents{
      gap: 150px;
    }
    .p-index-message__contents-img{
      width: 510px;
    }
    .p-index-message__contents-txtArea{
      width: calc(100% - 660px);
    }
    .p-index-message__contents-txt{
      margin-top: 50px;
    }
  }
}

/*  p-index-service
----------------------------------- */
.p-index-service{
  position: relative;
  padding: 102px 0 72px;
  background: $clr_bg;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100vw;
    mask: url(../img/common/img-deco.svg) no-repeat top center /contain;
    background: $clr_bg;
    bottom: 1px;
    translate: 0 100%;
    aspect-ratio: 1920/120;
  }
  .p-index-service__inner{
    @include inner;
  }
  .p-index-service__contents{
    margin-top: 32px;
    padding: 8px;
    background: #fff;
    border-radius: 10px;
  }
  .p-index-service__contents-ttl{
    padding: 12px 24px;
    background: $clr_primary_light;
    border-radius: 10px;
    @include font_title;
    text-align: center;
    strong{
      color: $clr_primary;
    }
  }
  .p-index-service__contents-img{
    padding: 16px;
  }
  @include mq (large){
    padding: 240px 0 70px;
    .p-index-service__contents{
      margin-top: 35px;
      padding: 16px 16px 0;
    }
    .p-index-service__contents-ttl{
      padding: 16px;
      font-size: font-size(20);
    }
    .p-index-service__contents-img{
      padding: 38px 64px;
    }
  }
}

/*  p-index-feature
----------------------------------- */
.p-index-feature{
  padding: 102px 0 60px;
  .p-index-feature__inner{
    @include inner;
  }
  .p-index-feature__contents{
    border: solid $clr_border_light;
    border-width: 1px 0;
  }
  .p-index-feature__contents-item{
    display: flex;
    flex-direction: column;
    padding: 32px 10px;
    border-bottom: 1px solid $clr_border_light;
    gap: 20px;
    &:last-child {
      border-bottom: none;
    }
    &.-orange{
      strong{
        color: $clr_primary;
      }
    }
    &.-green{
      strong{
        color: $clr_green;
      }
    }
    &.-blue{
      strong{
        color: $clr_blue;
      }
    }
  }
  .p-index-feature__contents-img{
    img{
      border-radius: 10px;
    }
  }
  .p-index-feature__contents-num{
    font-size: font-size(12);
    color: $clr_txt_gray;
  }
  .p-index-feature__contents-ttl{
    margin-top: 8px;
    @include font_title;
    font-size: font-size(22);
  }
  .p-index-feature__contents-txt{
    margin-top: 20px;
  }
  @include mq (large){
    padding: 240px 0 100px;
    .p-index-feature__contents-item{
      flex-direction: row;
      padding: 60px 20px;
      align-items: center;
      gap: 50px;
    }
    .p-index-feature__contents-img{
      width: 350px;
    }
    .p-index-feature__contents-txtArea{
      width: calc(100% - 400px);
    }
    .p-index-feature__contents-num{
      font-size: font-size(15);
    }
    .p-index-feature__contents-ttl{
      margin-top: 10px;
      font-size: font-size(26);
    }
    .p-index-feature__contents-txt{
      margin-top: 40px;
    }
  }
  @include mq (full){
    .p-index-feature__contents-item{
      gap: 110px;
    }
    .p-index-feature__contents-img{
      width: 530px;
    }
    .p-index-feature__contents-txtArea{
      width: calc(100% - 640px);
    }
    .p-index-feature__contents-ttl{
      font-size: font-size(32);
    }
    .p-index-feature__contents-txt{
      margin-top: 60px;
    }
  }
}

/*  p-index-setting
----------------------------------- */
.p-index-setting{
  .p-index-setting__inner{
    @include inner;
  }
  .p-index-setting__bg{
    padding: 50px 24px;
    background: $clr_bg_light;
  }
  .p-index-setting__note{
    margin-top: 10px;
  }
  .p-index-setting__note-item{
    position: relative;
    display: inline;
    padding-right: 20px;
    text-decoration: underline $clr_txt_gray;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 15px;
      height: 15px;
      top: 3px;
      right: 0;
      background: url(../img/common/icon-modal01.svg) no-repeat center /contain;
    }
    &:hover,&:focus-visible{
      text-decoration: none;
    }
  }
  .p-index-setting__contents{
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    gap: 8px;
  }
  .p-index-setting__contents-item{
    padding: 8px 8px 0 8px;
    background: #fff;
    border: 1px solid $clr_border_light;
    border-radius: 10px;
  }
  .p-index-setting__contents-img{
    padding: 16px 20px;
    background: $clr_green_light;
    border-radius: 10px;
  }
  .p-index-setting__contents-txt{
    padding: 18px 24px;
    strong{
      font-weight: 500;
    }
  }
  @include mq (large){
    .p-index-setting__bg{
      margin: 0 -220px;
      padding: 100px 220px;
    }
    .p-index-setting__note{
      margin-top: 10px;
    }
    .p-index-setting__note-item{
      &::after {
        top: 5px;
      }
    }
    .p-index-setting__contents{
      margin-top: 40px;
      flex-direction: row;
      gap: 20px;
    }
    .p-index-setting__contents-item{
      padding: 12px 12px 0 12px;
      width: calc(33.33% - (40px/3));
    }
    .p-index-setting__contents-img{
      padding: 22px 28px;
    }
    .p-index-setting__contents-txt{
      padding: 26px;
      text-align: center;
    }
  }
  @include mq (full){
    .p-index-setting__contents{
      gap: 40px;
    }
    .p-index-setting__contents-item{
      padding: 16px 16px 0 16px;
      width: calc(33.33% - (80px/3));
    }
    .p-index-setting__contents-img{
      padding: 12px 28px;
    }
  }
}

/*  p-index-flow
----------------------------------- */
.p-index-flow{
  position: relative;
  padding: 80px 0;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: -80px;
    background: url(../img/home/img-flow_S.png) no-repeat top /cover;
    z-index: -1;
  }
  .p-index-flow__inner{
    @include inner;
  }
  .p-index-flow__step{
    margin-top: 40px;
  }
  .p-index-flow__step-child-btnWrap{
    margin-top: 16px;
  }
  .p-index-flow__step-child-btn{
    margin: 0 auto;
    width: 256px;
    &:nth-child(n+2) {
      margin-top: 8px;
    }
  }
  .p-index-flow__step-child-ttl{
    margin-top: 14px;
    padding-bottom: 10px;
    font-weight: 500;
    border-bottom: 1px solid $clr_border;
  }
  .p-index-flow__step-child-list{
    margin-top: 10px;
  }
  .p-index-flow__step-child-note{
    margin-top: 4px;
  }
  @include mq (large){
    padding: 130px 0;
    &::before {
      top: 40px;
      left: -150px;
      background: url(../img/home/img-flow_L.png) no-repeat top right /cover;
    }
    .p-index-flow__head{
      margin-bottom: 35px;
    }
    .p-index-flow__txt{
      text-align: center;
    }
    .p-index-flow__step{
      margin-top: 80px;
    }
    .p-index-flow__step-child-ttl{
      padding-bottom: 8px;
    }
    .p-index-flow__step-child-list{
      margin-top: 12px;
    }
    .p-index-flow__step-child-note{
      margin-top: 8px;
    }
  }
}

/*  p-index-contact
----------------------------------- */
.p-index-contact{
  .p-index-contact__ttl-ja{
    font-size: font-size(28);
  }
  .p-index-contact__ttl-ja-small{
    display: block;
    font-size: font-size(18);
  }
  @include mq (large){
    .p-index-contact__ttl-ja-small{
      display: block;
      font-size: font-size(32);
    }
  }
  @include mq (full){
    .p-index-contact__ttl-ja-small{
      font-size: font-size(48);
    }
  }
}

/*  p-index-info
----------------------------------- */
.p-index-info{
  padding: 80px 0 100px;
  .p-index-info__inner{
    @include inner;
  }
  @include mq (large){
    padding: 130px 0;
  }
}

/*  p-index-faq
----------------------------------- */
.p-index-faq{
  position: relative;
  .p-index-faq__inner{
    @include inner;
  }
  .p-index-faq__bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background: $clr_bg;
    z-index: -1;
  }
  .p-index-faq__container{
    display: flex;
    flex-direction: column;
    padding: 80px 0;
  }
  .p-index-faq__container-head{
    margin-bottom: 40px;
  }
  .p-index-faq__container-head-icon{
    margin: 0 auto;
    width: 27px;
  }
  .p-index-faq__container-head-ttl{
    padding-top: 14px;
    @include font_title;
    font-size: font-size(26);
    text-align: center;
  }
  @include mq (large){
    .p-index-faq__bg{
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        aspect-ratio: 1280/340;
        background: url(../img/home/img-faq-bg.svg) no-repeat bottom /contain;
        z-index: -1;
      }
    }
    .p-index-faq__container-head{
      margin-bottom: 80px;
    }
    .p-index-faq__container-head-icon{
      width: 36px;
    }
    .p-index-faq__container-head-ttl{
      padding-top: 24px;
      font-size: font-size(40);
    }
  }
  @include mq (full){
    .p-index-faq__bg{
      margin-left: auto;
      right: 0;
      &::before {
        width: 70%;
        bottom: 50px;
      }
    }
    .p-index-faq__container{
      padding: 120px 0 100px;
      flex-direction: row;
      gap: 156px;
    }
    .p-index-faq__container-head{
      display: flex;
      width: 357px;
      align-self: start;
      align-items: center;
      gap: 24px;
    }
    .p-index-faq__container-head-ttl{
      padding-top: 0;
    }
    .p-index-faq__contents{
      width: calc(100% - 513px);
    }
  }
  @media all and (width >= 1600px) {
    .p-index-faq__bg{
      width: calc(100% - 160px);
    }
  }
}