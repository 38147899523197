@use '../../fundation/index' as *;

/*  js-fade-wrapper
----------------------------------- */
.js-fade-wrapper{
	.js-fade-elm{
		overflow: hidden;
		display: block;
		text-align: center;
		.js-fade-elm__in{
			display: inline-block;
			vertical-align: bottom;
			translate: 0 100%;
			opacity: 0;
			transition:
				translate 1s .3s cubic-bezier(0, 0, 0, 1),
				opacity 0.4s linear;
		}
	}
	&.is-active{
		.js-fade-elm__in{
			translate: 0 0;
			opacity: 1;
		}
	}
}