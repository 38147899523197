@use '../../fundation/index' as *;

/*  c-list01
----------------------------------- */
.c-list01{
  .c-list01__item{
    position: relative;
    padding-left: 16px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 6px;
      height: 2px;
      top: 15px;
      left: 0;
      background: $clr_default;
    }
    &:nth-child(n+2) {
      margin-top: 6px;
    }
  }
  @include mq (large){
    .c-list01__item{
      &::before {
        width: 8px;
        height: 3px;
      }
    }
  }
}