@use '../../fundation/index' as *;

/*  c-table01
----------------------------------- */
.c-table01{
  table{
    table-layout: fixed;
  }
  th,td{
    display: block;
  }
  tr{
    &:last-child {
      td{
        border-width: 0 1px 1px 1px;
      }
    }
  }
  th{
    padding: 16px 20px;
    @include font_title;
    text-align: left;
    color: #fff;
    background: $clr_primary;
  }
  td{
    padding: 20px;
    border: solid $clr_border_light;
    border-width: 0 1px;
  }
  a{
    display: inline-block;
    &:hover,&:focus-visible{
      text-decoration: none;
    }
  }
  @include mq (large){
    tr{
      &:first-child {
        td{
          border-width: 1px 1px 1px 0;
        }
      }
      &:last-child {
        th{
          border-bottom: none;
        }
      }
    }
    th,td{
      display: table-cell;
    }
    th{
      padding: 32px 50px;
      vertical-align: top;
      border-bottom: 1px solid $clr_border_light;
    }
    td{
      padding: 32px 50px 32px 40px;
      border-width: 0 1px 1px 0;
    }
  }
}