@use '../../fundation/index' as *;

/*  c-btn01
----------------------------------- */
.c-btn01{
  margin-top: 32px;
  text-align: center;
  .c-btn01__link{
    position: relative;
    display: inline-block;
    padding: 10px 35px 10px 20px;
    width: fit-content;
    background: $clr_primary;
    border-radius: 500px;
    @include trans-normal;
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 12px;
      height: 8px;
      top: 18px;
      right: 10px;
      mask: url(../img/common/icon-arw.svg) no-repeat top /contain;
      background: #fff;
    }
    &:hover,&:focus-visible{
      padding: 10px 30px 10px 15px;
      margin: 0 10px;
      .js-mouseStalker__contents {
        background: $clr_blue;
      }
    }
  }
  .c-btn01__txt{
    color: #fff;
    font-size: font-size(14);
    font-weight: 500;
  }
  // .c-btn01__txt-arw{
  //   position: relative;
  //   &::before {
  //     content: '';
  //     position: absolute;
  //     display: block;
  //     width: 16px;
  //     height: 12px;
  //     top: 5px;
  //     right: -25px;
  //     mask: url(../img/common/icon-arw.svg) no-repeat top /contain;
  //     background: #fff;
  //   }
  // }
  @include mq (large){
    margin-top: 40px;
    .c-btn01__link{
      padding: 16px 88px 16px 32px;
      &::before {
        width: 16px;
        height: 12px;
        right: 32px;
        top: 25px;
      }
      &:hover,&:focus-visible{
        padding: 16px 70px 16px 26px;
      }
    }
    .c-btn01__txt{
      font-size: font-size(17);
      // &::before {
      //   width: 16px;
      //   height: 12px;
      //   right: -60px;
      //   top: 10px;
      // }
    }
    // .c-btn01__txt-arw{
    //   &::before {
    //     top: 7px;
    //     right: -55px;
    //   }
    // }
  }
}

.c-btn01.-bgWhite{
  .c-btn01__link{
    background: #fff;
    &::before {
      background: $clr_default;
    }
    &:hover,&:focus-visible{
      background: $clr_primary;
      &::before {
        background: #fff;
      }
      .c-btn01__txt{
        color: #fff;
      }
    }
  }
  .c-btn01__txt{
    color: $clr_default;
  }
}

.c-btn01.-pcLeft{
  @include mq (large){
    text-align: left;
  }
}

.c-btn01.-pcRight{
  @include mq (large){
    text-align: right;
  }
}

// マウスストーカー
.js-mouseStalker {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1px;
  height: 1px;
  top: 50%;
  left: 50%;
  pointer-events: none;
  // opacity: 0;
  z-index: 1000;
}
.js-mouseStalker.is-active{
  .js-mouseStalker__contents{
    background: #fff;
    box-shadow: 0px 0px 60px 10px #fff;
    filter: blur(10px);
    opacity: .4;
  }
}
.js-mouseStalker__contents {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  flex-shrink: 0;
}



/*  c-btn02
----------------------------------- */
.c-btn02{
  .c-btn02__link{
    display: block;
    padding: 14px;
    border: 1px solid $clr_border;
    border-radius: 500px;
    @include trans-normal;
    &:hover,&:focus-visible{
      background: $clr_primary;
      .c-btn02__txt{
        color: #fff;
      }
    }
  }
  .c-btn02__txt{
    text-align: center;
    color: $clr_primary;
    font-size: font-size(15);
    font-weight: 500;
  }
  @include mq (large){
    .c-btn02__link{
      padding: 10px;
    }
  }
}