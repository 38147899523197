@use '../../fundation/index' as *;

/*  p-privacy
----------------------------------- */
.p-privacy{
  padding: 80px 0;
  .p-privacy__inner{
    @include inner(960px);
  }
  .p-privacy__txt{
    padding: 24px 32px;
    background: $clr_bg;
    border-radius: 10px;
  }
  .p-privacy__contents{
    margin-top: 60px;
  }
  .p-privacy__contents-item{
    &:nth-child(n+2) {
      margin-top: 40px;
    }
  }
  .p-privacy__contents-ttl{
    @include font_title;
    font-size: font-size(20);
  }
  .p-privacy__contents-txt{
    margin-top: 16px;
  }
  .p-privacy__contents-link{
    display: inline-block;
    text-decoration: underline;
    &:hover,&:focus-visible{
      text-decoration: none;
    }
  }
  @include mq (large){
    padding: 85px 0 130px;
    .p-privacy__txt{
      padding: 32px 50px;
    }
    .p-privacy__contents{
      margin-top: 80px;
    }
    .p-privacy__contents-item{
      &:nth-child(n+2) {
        margin-top: 50px;
      }
    }
    .p-privacy__contents-txt{
      margin-top: 10px;
      font-size: font-size(16);
    }
  }
}