@use '../../fundation/index' as *;

/*  c-accordion01
----------------------------------- */
.c-accordion01{
  .c-accordion01__item{
    background: #fff;
    border-radius: 10px;
    @include trans-normal;
    &:nth-child(n + 2) {
      margin-top: 16px;
    }
    &:hover,&:focus-visible{
      .c-accordion01__title{
        color: $clr_primary;
      }
    }
  }
  .c-accordion01__titleWrap {
    position: relative;
    padding: 20px 54px 20px 25px;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 24px;
      translate: 0 -50%;
      width: 14px;
      height: 2px;
      background: $clr_border;
      @include trans-normal;
    }
    &::after {
      rotate: -90deg;
    }
  }
  .c-accordion01__title{
    position: relative;
    padding-left: 30px;
    font-size: font-size(18);
    font-weight: 500;
    &::before {
      content: 'Q';
      position: absolute;
      display: block;
      @include font_title;
      font-size: font-size(24);
      color: $clr_primary;
      top: -2px;
      left: 0;
    }
  }
  .c-accordion01__contents{
    position: relative;
    height: fit-content;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      width: calc(100% - 48px);
      height: 1px;
      top: 0;
      left: 50%;
      translate: -50% 0;
      background: $clr_border;
    }
  }
  .c-accordion01__contents__inner{
    padding: 20px 32px;
  }
  .c-accordion01__item[open]{
    .c-accordion01__titleWrap {
      &::after {
        rotate: 0deg;
      }
    }
    .c-accordion01__title{
      color: $clr_primary;
    }
  }
  @include mq (large){
    .c-accordion01__titleWrap{
      padding: 24px 40px;
      &::before,
      &::after {
        right: 40px;
      }
    }
    .c-accordion01__title{
      font-size: font-size(19);
    }
    .c-accordion01__contents {
      &::before {
        width: calc(100% - 80px);
      }
    }
    .c-accordion01__contents__inner{
      padding: 24px 40px 32px;
    }
  }
}