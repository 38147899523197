@charset "UTF-8";

/*  color
================================================ */
$clr_default: #2F1908;
$clr_txt_gray: #938A83;
$clr_primary: #EC6C00;
$clr_primary_light: #F5E4C4;
$clr_blue: #4B74C3;
$clr_blue_light: #DCE6F4;
$clr_green: #3D904A;
$clr_green_light: #E4EFE6;
$clr_border: #DBCDC2;
$clr_border_light: #E3E2E0;
$clr_bg: #F4EDE7;
$clr_bg_light: #F5F5F5;
$shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);

//:root{
//	.hoge{
//		--primary: #{$clr_primary};
//		--secondary: #{$clr_secondary};
//		--ico: url() no-repeat center /contain;
//	}
//}

/*  width
================================================ */
$contents_width: 1280px;
$padding: 20px; //左右の余白を指定
$contents_plus_padding: calc($contents_width + ($padding * 2)); //1320px
$adjust: $padding * 6;
$boundary: 768px + ($adjust * 2);

//inner
@mixin inner( $contentWidth: $contents_width){
	width: min(100%, #{$contentWidth + ($padding * 2)});
	margin: 0 auto;
	padding-left: $padding;
	padding-right: $padding;
	@media all and ( 768px <= width < $contents_plus_padding ){
		width: min(100%, $contentWidth + ($adjust * 2) - ($padding * 2) );
		padding-left: $adjust;
		padding-right: $adjust;
	}
}
// breakpoints
$breakpoints: (
	'small': 'all and ( width < 768px)',
	'medium': 'all and ( 768px <= width )',
	'large': 'all and ( ' + $boundary +' <= width )', //768 + $adjust x 2(両サイド＋768px でコンテンツ自体は768pxのサイズでおさまる範囲)
	'full': 'all and ( ' +  $contents_plus_padding +' < width )',
	//'max': 'all and ( px <= width	)',
) !default;
@mixin mq($breakpoint: tb) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

/*  transition
================================================ */
$speed: cubic-bezier(0.5, 0.26, 0.23, 0.93);

@mixin trans-normal( $prop: all ){
	transition: .25s $speed;
	transition-property: $prop;
}

/*  font
================================================ */
@function font-size($font-size-px) {
  @return calc($font-size-px / 16 * 1rem);
}

@mixin font_base(){
	font-family: "Murecho", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
	line-height: 1.8;
	letter-spacing: .04em;
	font-weight: 400;
	font-size: font-size(16);
	@include mq (medium) {
		font-size: font-size(17);
	}
}

@mixin font_title(){
	line-height: 1.6;
	letter-spacing: .06em;
	font-weight: 700;
}

/*  vw計算
---------------------------------------- */
@function vw($pc, $baseViewportWidth: 1920) {
	$max-size: $pc * 1px;
	$min-size: calc(($pc * 0.75) * (100 / 1920 * 1vw));
	$variable-size: calc($pc / $baseViewportWidth) * 100vw;
	@return clamp($min-size,$variable-size,$max-size);
}

@function vwFont($pc, $baseViewportWidth: 1920) {
	$max-size: calc($pc / 16) * 1rem;
	$min-size: calc($pc / 16) * 0.75rem;
	$variable-size: calc($pc / $baseViewportWidth) * 100vw;
	@return clamp($min-size,$variable-size,$max-size);
}