@use '../../fundation/index' as *;

/*  c-text-note01
----------------------------------- */
.c-text-note01{
  padding-left: 16px;
  text-indent: -17px;
  .c-text-note01__item{
    color: $clr_txt_gray;
    font-size: font-size(13);
    &:nth-child(n+2) {
      margin-top: 2px;
    }
  }
  @include mq (large){
    .c-text-note01__item{
      font-size: font-size(15);
    }
  }
}