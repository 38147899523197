@use '../../fundation/index' as *;

/*  c-breadcrumb
----------------------------------- */
.c-breadcrumb{
  margin-top: 20px;
  .c-breadcrumb__inner{
    @include inner;
  }
	.c-breadcrumb__list{
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
		font-size: font-size(13);
    span{
      color: $clr_default;
    }
		a{
			display: inline-block;
			color: $clr_default;
			@include trans-normal;
			&:hover,&:focus-visible{
				opacity: 0.5;
			}
		}
	}
  .c-breadcrumb__item{
    position: relative;
    &:nth-child(n+2) {
      &::before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: -11px;
        translate: -50% -50%;
        width: 12px;
        height: 2px;
        background: $clr_border_light;
      }
      span{
        color: $clr_txt_gray;
      }
    }
  }
}