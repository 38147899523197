@use '../../fundation/index' as *;

/*  p-contact
----------------------------------- */
.p-contact{
  padding: 80px 0;
  .p-contact__inner{
    @include inner(960px);
  }
  .p-contact__contents{
    margin-top: 24px;
    padding: 24px 16px 16px;
    background: $clr_bg;
    border-radius: 10px;
  }
  .p-contact__contents-txt{
    margin-bottom: 24px;
    padding: 0 16px;
  }
  .p-contact__contents-txt-primary{
    color: $clr_primary;
  }
  .p-contact__contents-item{
    padding: 24px 16px 0;
  }
  .p-contact__contents-form{
    margin-top: 40px;
  }
  @include mq (large){
    padding: 80px 0 130px;
    .p-contact__txt{
      text-align: center;
    }
    .p-contact__contents{
      margin-top: 60px;
      padding: 40px 80px;
    }
    .p-contact__contents-txt{
      margin-bottom: 32px;
      padding: 0;
      text-align: center;
    }
    .p-contact__contents-form{
      margin-top: 80px;
    }
  }
}