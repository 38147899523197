@use '../../fundation/index' as *;

/*  c-hero
---------------------------------------- */
.c-hero01{
  position: relative;
  padding: 70px 0;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $clr_bg_light;
    z-index: -1;
  }
  &.-noPb{
    padding: 70px 0 0;
    &::before {
      height: 205px
    }
  }
  .c-hero01__inner{
    @include inner;
  }
  .c-hero01__text{
    text-align: center;
  }
  .c-hero01__text-en{
    color: $clr_txt_gray;
    font-size: font-size(13);
    text-transform: capitalize;
  }
  .c-hero01__text-ja{
    margin-top: 10px;
    font-size: font-size(24);
    vertical-align: 0.02em;
    font-weight: 700;
  }
	.c-hero01__contents{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding: 24px;
    width: 100%;
    background: #fff;
    border: 1px solid $clr_border_light;
    border-radius: 10px;
	}
  .c-hero01__contents-item{
    display: flex;
    padding: 16px 0;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid $clr_border_light;
    &:first-child {
      padding: 0 0 16px 0;
    }
    &:last-child {
      padding: 16px 0 0 0;
      border-bottom: none;
    }
  }
  .c-hero01__contents-item-img{
    width: 60px;
  }
  .c-hero01__contents-item-txt{
    width: calc(100% - 80px);
    font-size: font-size(14);
    line-height: 1.6;
  }
  @include mq (large){
    margin: 50px 50px 0;
    padding: 100px 0;
    &.-noPb{
      &::before {
        height: 380px
      }
    }
    .c-hero01__text-en{
      font-size: font-size(16);
    }
    .c-hero01__text-ja{
      margin-top: 16px;
      font-size: font-size(44);
    }
    .c-hero01__contents{
      margin-top: 50px;
      padding: 32px 20px;
      flex-direction: row;
      gap: 10px;
    }
    .c-hero01__contents-item{
      padding: 0 !important;
      flex-direction: column;
      width: calc(20% - (40px/5));
      border-bottom: none;
      gap: 10px;
    }
    .c-hero01__contents-item-txt{
      width: 100%;
      text-align: center;
    }
    .c-hero01__contents-item-img{
      width: 100px;
    }
  }
  @include mq (full){
    &.-noPb{
      padding: 90px 0 0;
    }
    .c-hero01__contents{
      padding: 32px 70px;
      gap: 40px;
    }
    .c-hero01__contents-item{
      width: calc(20% - (160px/5));
    }
    .c-hero01__contents-item-txt{
      font-size: font-size(17);
    }
  }
}