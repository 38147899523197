@use '../fundation/index' as *;

/*  header
----------------------------------- */
.l-header{
	// position: fixed;
	position: relative;
	width: 100%;
	height: 60px;
	top: 0;
	left: 0;
	z-index: 100;
	.l-header__inner{
		position: fixed;
		display: grid;
		padding: 0 20px;
		width: 100%;
		height: 60px;
		align-items: center;
		background: #fff;
		@include trans-normal;
	}
	.l-header__logo{
		width: 120px;
	}
	.l-header__logo-link{
		display: block;
		@include trans-normal;
		&:hover,&:focus-visible{
			opacity: .5;
		}
	}
	@include mq (large){
		height: 150px;
		.l-header__inner{
			padding: 0;
			height: 149px;
			grid-template: "logo contact" 1fr "menu menu" 1fr / 1fr;
		}
		.l-header__logo{
			margin-left: 24px;
			width: 190px;
			grid-area: logo;
		}
		.l-header__contact{
			grid-area: contact;
		}
		.l-header__nav{
			grid-area: menu;
		}
		.l-header__contact-list{
			display: flex;
			align-items: center;
		}
		.l-header__contact-tel{
			padding: 9px 28px 9px 30px;
		}
		.l-header__contact-tel-link{
			position: relative;
			display: block;
			@include font_title;
			font-size: font-size(24);
			@include trans-normal;
			&::before {
				content: '';
				position: absolute;
				display: block;
				width: 24px;
				height: 24px;
				top: 9px;
				left: -30px;
				background: url(../img/common/icon-tel.svg) no-repeat center /contain;
			}
			&:hover,&:focus-visible{
				opacity: .5;
			}
		}
		.l-header__contact-item-txt{
			font-size: font-size(13);
			color: $clr_txt_gray;
		}
		.l-header__contact-link{
			display: block;
			padding: 25px 28px;
			@include trans-normal;
			&:hover,&:focus-visible{
				&.-mail{
					background: $clr_primary_light;
					.l-header__contact-link-txt{
						color: $clr_primary;
						&::before {
							background: url(../img/common/icon-mail.svg) no-repeat center /contain;
						}
					}
				}
				&.-contact{
					background: #DCFAE8;
					.l-header__contact-link-txt{
						color: #06C755;
						&::before {
							background: url(../img/common/icon-contact-green.svg) no-repeat center /contain;
						}
					}
				}
			}
			&.-mail{
				background: $clr_primary;
				.l-header__contact-link-txt{
					&::before {
						background: url(../img/common/icon-mail-white.svg) no-repeat center /contain;
					}
				}
			}
			&.-contact{
				background: #06C755;
				.l-header__contact-link-txt{
					&::before {
						background: url(../img/common/icon-contact.svg) no-repeat center /contain;
					}
				}
			}
		}
		.l-header__contact-link-txt{
			position: relative;
			padding-left: 40px;
			@include font_title;
			color: #fff;
			font-size: font-size(19);
			&::before {
				content: '';
				position: absolute;
				display: block;
				width: 26px;
				height: 26px;
				top: 4px;
				left: 0;
			}
		}
	}
}

/*  drawer
----------------------------------- */
.l-drawer {
	position: fixed;
	top: 60px;
	left: 0;
	width: 100%;
	max-height: calc(100vh - 60px);
	background: #fff;
	overflow: auto;
	transform: translateX(120%);
	@include trans-normal;
	&::-webkit-scrollbar {
		display: none;
	}
	&[aria-hidden="false"] {
		transform: translateX(0);
	}
	.l-drawer__contents{
		padding: 40px;
		background: $clr_bg;
	}
	.l-drawer__contents-list-in{
		padding-bottom: 48px;
	}
	.l-drawer__contents-item{
		border-bottom: 1px solid $clr_border;
		&:first-child {
			.l-drawer__contents-item-link{
				padding: 0 0 32px 0;
			}
		}
		&:nth-child(n+2) {
			.l-drawer__contents-item-link{
				padding: 32px 0;
			}
		}
		&.-hasContents{
			padding: 32px 0 24px;
		}
	}
	.l-drawer__contents-item-link{
		display: block;
		font-size: font-size(18);
		@include trans-normal;
		font-weight: 500;
		&:hover,&:focus-visible{
			opacity: .5;
		}
	}
	.l-drawer__contents-item-txt{
		font-size: font-size(18);
		font-weight: 500;
	}
	.l-drawer__item-contents{
		padding-top: 32px;
	}
	.l-drawer__item-contents-item{
		position: relative;
		padding-left: 20px;
		&::before {
			content: '';
			position: absolute;
			display: block;
			width: 10px;
			height: 3px;
			background: $clr_border;
			top: 15px;
			left: 0;
		}
		&:nth-child(n+2) {
			margin-top: 20px;
		}
	}
	.l-drawer__item-contents-item-link{
		display: inline-block;
		@include trans-normal;
		&:hover,&:focus-visible{
			opacity: .5;
		}
	}
	.l-drawer__contact-item{
		margin: 0 auto;
		width: 260px;
		&:nth-child(n+2) {
			margin-top: 10px;
		}
	}
	.l-drawer__contact-icon{
		display: inline-block;
		margin-right: 12px;
		width: 28px;
		height: 28px;
		vertical-align: middle;
	}
	.l-drawer__contact-link{
		display: block;
		padding: 18px 20px;
		color: #fff;
		@include font_title;
		text-align: center;
		@include trans-normal;
		&:hover,&:focus-visible{
			opacity: .5;
		}
		&.-contact{
			background: #06C755;
			.l-drawer__contact-icon{
				background: url(../img/common/icon-contact.svg) no-repeat center /contain;
			}
		}
		&.-mail{
			background: $clr_primary;
			.l-drawer__contact-icon{
				background: url(../img/common/icon-mail-white.svg) no-repeat center /contain;
			}
		}
	}
	.l-drawer__contents-tel{
		margin: 20px auto 0;
		width: 230px;
	}
	.l-drawer__contents-tel-link{
		position: relative;
		display: block;
		padding-left: 39px;
		color: $clr_default;
		@include font_title;
		font-size: font-size(24);
		@include trans-normal;
		&::before {
			content: '';
			position: absolute;
			display: block;
			width: 32px;
			height: 32px;
			top: 3px;
			left: 0;
			background: url(../img/common/icon-tel.svg) no-repeat center /contain;
		}
		&:hover,&:focus-visible{
			opacity: .5;
		}
	}
	.l-drawer__contact-item-txt{
		color: $clr_txt_gray;
		font-size: font-size(13);
		text-align: center;
	}
	@include mq (large){
		.l-drawer__contents{
			display: none;
		}
	}
}

/*  l-header__btn
----------------------------------- */
.l-header__btnWrap{
	position: fixed;
	display: flex;
	justify-content: flex-end;
	width: 120px;
	height: 60px;
	top: 0;
	right: 0;
	.l-header__btn{
		position: relative;
		padding: 7px;
		width: 50%;
		&.-contact{
			background: #06C755;
			.l-header__btn-icon{
				display: block;
				margin: 0 auto;
				width: 26px;
				height: 26px;
				background: url(../img/common/icon-contact.svg) no-repeat center /contain;
			}
			.l-header__btn-text{
				letter-spacing: -0.04em;
			}
		}
		&.-menu{
			background: $clr_primary;
			.l-header__btn-text{
				padding-top: 6px;
				letter-spacing: -0.1em;
			}
		}
	}
	.l-header__btn-text{
		display: block;
		font-size: font-size(12);
		font-weight: 500;
		color: #fff;
	}
	.l-header__btn-line {
    display: block;
    position: relative;
		margin: 0 auto;
    width: 26px;
    height: 20px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      translate: -50% 0;
      width: 100%;
      height: 3px;
      background: #fff;
			@include trans-normal;
    }
    &::before {
      top: 3px;
    }
    &::after {
      top: 19px;
    }
  }
	.l-header__btn-line-in{
		position: absolute;
		display: block;
		width: 26px;
		height: 3px;
		top: 50%;
		right: 50%;
		translate: 50% 50%;
		background: #fff;
	}
  .l-header__btn[aria-expanded="true"] {
    .l-header__btn-line {
      &::before,
      &::after {
        top: 8px;
      }
      &::before {
        rotate: 45deg;
      }
      &::after {
        rotate: -45deg;
      }
    }
    .l-header__btn-line-in {
      opacity: 0;
    }
  }
}

/*  l-gnav
----------------------------------- */
.l-gnav{
	@include mq (large){
		background: #fff;
		border: solid $clr_border_light;
		border-width: 1px 0;
		.l-gnav__inner{
			@include inner;
		}
		.l-gnav__list{
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.l-gnav__item{
			padding: 18px 0;
			width: min(320px,100%);
			&:hover,&:focus-visible{
				.l-gnav__item-link,
				.l-gnav__item-trigger{
					color: $clr_primary;
				}
			}
			&:nth-child(2) {
				.l-gnav__item-text{
					border-width: 0 1px 0 0;
				}
			}
		}
		.l-gnav__item-text{
			text-align: center;
			border: solid $clr_border_light;
			border-width: 0 1px;
		}
		.l-gnav__item-link,
		.l-gnav__item-trigger{
			display: block;
			font-size: font-size(17);
			font-weight: 500;
			@include trans-normal;
			pointer-events: auto;
			text-align: center;
			cursor: pointer;
		}
		.l-gnav__item-trigger{
			background: transparent;
		}
		.l-gnav__item-trigger-icon{
			display: inline-block;
			margin-left: 10px;
			width: 12px;
			height: 8px;
			cursor: pointer;
			vertical-align: 4px;
			background: url(../img/common/icon-menu.svg) no-repeat center /contain;
		}
		.l-gnav__item-contents{
			position: absolute;
			width: 320px;
			top: 80px;
      pointer-events: none;
			@include trans-normal;
			opacity: 0;
		}
		.l-gnav__item-contents-list{
			padding: 24px 32px;
			border: 1px solid $clr_border_light;
			background-color: #fff;
		}
		.l-gnav__item-contents-item{
			&:first-child {
				padding: 0 0 24px;
			}
			&:nth-child(n+2){
				padding: 24px 0;
				border-top: 1px solid $clr_border_light;
			}
			&:last-child {
				padding: 24px 0 0;
			}
		}
		.l-gnav__item-contents-item-link{
			display: block;
			padding: 7px 0;
			@include trans-normal;
			&:hover,&:focus-visible{
				color: $clr_primary;
			}
		}
	}
	@include mq (full){
		.l-gnav__item-trigger{
			&::before {
				right: 80px;
			}
		}
	}
}


/*  animation
---------------------------------------- */
.js-header{
	@include mq (large){
		transition: none;
		.l-header__btn{
			display: none;
		}
		&.is-hidden{
			// position: fixed;
			position: relative;
			opacity: 0;
			pointer-events: none;
			// translate: 0 -100%;
			transition: translate 0.5s ease-in-out;
			width: 100%;
			margin-right: 0;
			@include trans-normal;
			// .l-header__inner{
				// translate: 0 -100%;
			// }
		}
		&.is-active{
			// position: fixed;
			opacity: 1;
			pointer-events: auto;
			// translate: 0 0;
			width: 100%;
			margin-right: 0;
			.l-header__inner{
				padding-right: 0;
				translate: 0 0;
			}
			.l-gnav__item-trigger,
			.l-gnav__item-link{
				color: $clr_default;
			}
			.l-gnav__btn-link{
				display: grid;
				place-content: center;
				border: 1px solid $clr_primary;
				background: $clr_primary;
				height: var(--headerH);
			}
		}
	}
	@include mq (full){
		&.is-active{
			.l-header__inner{
				padding-right: var(--headerH);
			}
			.l-header__btn{
				display: block;
			}
		}
	}
}

// js-gnav-toggle
.js-gnav-toggle{
	.js-gnav-toggle__trigger{
		&.is-active{
			color: $clr_primary;
			.l-gnav__item-trigger-icon{
				rotate: -180deg;
			}
		}
	}
	.js-gnav-toggle__contents{
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.2s linear;
		&.is-active{
			pointer-events: auto;
			opacity: 1;
		}
	}
}