@use '../fundation/index' as *;

/*  footer
----------------------------------- */
.l-footer{
  // position: relative;
  padding-top: 102px;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   display: block;
  //   width: 100vw;
  //   height: 100%;
  //   top: -1px;
  //   mask: url(../img/common/img-deco.svg) no-repeat top center /contain;
  //   background: $clr_primary;
  //   z-index: -1;
  //   aspect-ratio: 1920/120;
  // }
  &.-hasBorder{
    padding-top: 80px;
    border-top: 1px solid $clr_border_light;
    &::before {
      display: none;
    }
  }
  .l-footer__inner{
    @include inner;
  }
  .l-footer__contents-info-logo{
    margin: 0 auto;
    width: 160px;
  }
  .l-footer__contents-info-logo-link{
    display: block;
    @include trans-normal;
    &:hover,&:focus-visible{
      opacity: .5;
    }
  }
  .l-footer__contents-info-txt{
    padding-top: 16px;
    text-align: center;
    line-height: 1.5;
    font-size: font-size(13);
  }
  .l-footer__privacy{
    padding-top: 40px;
    text-align: center;
  }
  .l-footer__privacy-link{
    display: inline-block;
    font-size: font-size(12);
    color: $clr_txt_gray;
    text-align: center;
    @include trans-normal;
    &:hover,&:focus-visible{
      opacity: .5;
    }
  }
  .l-footer__bottomWrap{
    margin-top: 70px;
    padding: 16px 0;
    border-top: 1px solid $clr_border_light;
  }
  .l-footer__bottom-inner{
    @include inner;
  }
  .l-footer__bottom-copy{
    text-align: center;
    color: $clr_txt_gray;
    font-size: font-size(12);
  }
  .l-footer__bottom-privacy-link{
    display: inline-block;
  }
  @include mq (large){
    padding-top: 270px;
    .l-footer__contents{
      display: flex;
      justify-content: space-between;
      gap: 30px;
    }
    .l-footer__contents-nav{
      display: flex;
      gap: 40px;
    }
    .l-footer__contents-info-logo{
      margin: auto 0;
      width: 295px;
    }
    .l-footer__contents-info-txt{
      padding-top: 32px;
      text-align: left;
    }
    .l-footer__contents-info-txt-name,
    .l-footer__contents-info-txt-add{
      font-size: font-size(15);
      line-height: 1.6;
    }
    .l-footer__contents-nav-item{
      &:nth-child(n+2) {
        padding-top: 24px;
      }
    }
    .l-footer__contents-nav-item-link,
    .l-footer__contents-nav-item-sub-item-link{
      display: inline-block;
      font-size: font-size(15);
      @include trans-normal;
      &:hover,&:focus-visible{
        opacity: .5;
      }
    }
    .l-footer__contents-nav-item-title{
      font-size: font-size(15);
    }
    .l-footer__contents-nav-item-sub{
      padding-top: 20px;
    }
    .l-footer__contents-nav-item-sub-item{
      position: relative;
      padding-left: 16px;
      font-size: font-size(14);
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 8px;
        height: 2px;
        top: 12px;
        left: 0;
        background: $clr_border_light;
      }
      &:nth-child(n+2) {
        margin-top: 8px;
      }
    }
    .l-footer__bottomWrap{
      margin-top: 120px;
      padding: 20px 0;
    }
    .l-footer__bottom-contents{
      display: flex;
    }
    .l-footer__bottom-copy,
    .l-footer__bottom-privacy{
      width: 50%;
    }
    .l-footer__bottom-copy{
      text-align: left;
    }
    .l-footer__bottom-privacy{
      display: inline-block;
      font-size: font-size(12);
      color: $clr_txt_gray;
      text-align: right;
      @include trans-normal;
      &:hover,&:focus-visible{
        opacity: .5;
      }
    }
  }
  @include mq (full){
    .l-footer__contents-nav{
      gap: 80px;
    }
  }
}