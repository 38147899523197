@use '../../fundation/index' as *;

/*  c-modal01
----------------------------------- */
.c-modal01{
  position: relative;
  display: block;
  .c-modal01__contents{
    position: absolute;
    margin-top: 10px;
    padding: 10px 12px;
    box-shadow: $shadow;
    background: #fff;
    opacity: 0;
    @include trans-normal;
    &.is-active{
      opacity: 1;
    }
  }
  .c-modal01__contents-ttl{
    color: $clr_default;
    font-size: font-size(15);
    font-weight: 500;
    text-indent: 0;
  }
  .c-modal01__contents-table{
    margin-top: 10px;
    table{
      table-layout: fixed;
      border: 1px solid $clr_border;
    }
    th,td{
      display: block;
      padding: 20px;
      width: 100%;
      color: $clr_default;
    }
    th{
      font-weight: 400;
      border-bottom: 1px solid $clr_border;
      background: $clr_border_light;
    }
  }
  @include mq (large){
    .c-modal01__contents{
      padding: 20px 24px;
      width: min(620px, 100%);
      left: 19%;
    }
    .c-modal01__contents-table{
      margin-top: 14px;
      tr{
        &:nth-child(n+2) {
          border-top: 1px solid $clr_border;
        }
      }
      th,td{
        display: table-cell;
        padding: 14px 16px;
      }
      th{
        width: 207px;
      }
    }
  }
}