@use '../../fundation/index' as *;

/*  c-layout-info
----------------------------------- */
.c-layout-info{
  display: flex;
  flex-direction: column;
  gap: 16px;
  .c-layout-info__item-link{
    display: grid;
    @include trans-normal;
    &:hover,&:focus-visible{
      color: $clr_primary;
    }
  }
  .c-layout-info__item-img{
    img{
      border-radius: 10px 10px 0 0;
    }
  }
  .c-layout-info__item-ttlArea{
    padding: 20px 24px;
    border-radius: 0 0 10px 10px;
    background: $clr_bg_light;
    border: solid $clr_border_light;
    border-width: 0 1px 1px;
  }
  .c-layout-info__item-ttl{
    @include font_title;
  }
  @include mq (large){
    flex-direction: row;
    gap: 40px;
    .c-layout-info__item{
      display: grid;
      width: calc(50% - 20px);
    }
    .c-layout-info__item-link{
      grid-template-rows: auto 1fr;
    }
    .c-layout-info__item-ttlArea{
      padding: 32px;
    }
    .c-layout-info__item-ttl{
      font-size: font-size(19);
    }
  }
}

/*  c-layout-step01
----------------------------------- */
.c-layout-step01{
  display: grid;
  justify-content: center;
  gap: 16px;
  .c-layout-step01__item{
    padding: 20px 16px 16px 16px;
    background: $clr_blue_light;
    border-radius: 10px;
  }
  .c-layout-step01__num{
    text-align: center;
    font-size: font-size(12);
    color: $clr_blue;
  }
  .c-layout-step01__icon{
    margin: 8px auto 0;
    width: 32px;
  }
  .c-layout-step01__ttl{
    margin-top: 12px;
    @include font_title;
    font-size: font-size(20);
    text-align: center;
  }
  .c-layout-step01__child{
    margin-top: 24px;
    padding: 24px;
    background: #fff;
    border-radius: 10px;
  }
  @include mq (large){
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    .c-layout-step01__item{
      display: grid;
      padding: 32px 16px 16px;
      grid-template-rows: subgrid;
      grid-row: span 4;
      gap: 0;
    }
    .c-layout-step01__icon{
      display: inline-flex;
      margin-top: 8px;
      width: 34px;
      align-items: center;
    }
    .c-layout-step01__ttl{
      margin-top: 20px;
      font-size: font-size(24);
    }
    .c-layout-step01__child{
      margin-top: 32px;
      padding: 32px 40px;
    }
  }
  @include mq (full){
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}

.c-layout-step01.-col2{
  @include mq (large){
    grid-template-columns: repeat(2, 1fr);
  }
}