@use './variable' as *;

/*  base
================================================ */
html {
	overflow: auto;
	scroll-behavior: smooth;
	scroll-padding-top: 60px;
	&:not([lang="ja"]) {
		word-break: break-word;
	}
	@include mq (large){
		scroll-padding-top: 150px;
	}
}
body {
	overflow: hidden;
	min-width: 320px;
	font-feature-settings: "palt";
	color: $clr_default;
	@include font_base();
	@include mq(medium) {
		min-width: auto;
	}
}