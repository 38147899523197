@use '../../fundation/index' as *;

/*  デフォルト
----------------------------------- */
input,textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  border-radius: 0;
  border: 1px solid $clr_border_light;
  background: #fff;
}
input[type='text'],
input[type="email"],
textarea{
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
}
input::placeholder {
  color: $clr_txt_gray;
  letter-spacing: 0.04em;
}
input:-ms-input-placeholder {
  color: $clr_txt_gray;
  letter-spacing: 0.04em;
}
input::-ms-input-placeholder {
  color: $clr_txt_gray;
  letter-spacing: 0.04em;
}
button{
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
}
label{
  cursor: pointer;
}
select{
  box-sizing: border-box;
  padding: 5px 30px 5px 10px;
  border-radius: 0;
  border: 1px solid #abacab;
  background: #fff;
}
input[type='radio']{
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
  &:focus{
    +span{
      &::before{
        // background: $clr_bg;
        background: #cbffb1;
      }
    }
  }
  +span{
    position: relative;
    display: inline-block;
    padding-left: 28px;
    &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border: 1px solid $clr_default;
      background: #fff;
      border-radius: 50%;
    }
  }
}
input[type='radio']:checked{
  +span{
    &::before {
      border-color: $clr_primary;
    }
    &::after{
      content: '';
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $clr_primary;
    }
  }
}
input[type='checkbox']{
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
  &:focus{
    +span{
      &::before{
        background: $clr_bg;
      }
    }
  }
  +span{
    position: relative;
    display: inline-block;
    padding-left: 30px;
    &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background: #fff;
      border: 1px solid $clr_default;
    }
  }
}
input[type='checkbox']:checked{
  +span{
    &::before {
      border-color: $clr_primary;
    }
    &::after{
      content: '';
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      width: 12px;
      height: 11px;
      background: url(../img/common/icon-check.svg) no-repeat center /contain;
    }
  }
}

/*  c-form01
----------------------------------- */
.c-form01{
  .c-form01__contents{
    margin: 0 -20px;
    padding: 50px 20px;
    background: $clr_bg_light;
  }
  .c-form01__item{
    &:nth-child(n+2) {
      margin-top: 32px;
    }
    &.-privacy{
      display: grid;
      grid-template: "ttl link" auto "check check" auto;
      .c-form01__ttl{
        grid-area: ttl;
      }
      .c-form01__policy{
        grid-area: link;
      }
      .c-form01__item-input{
        grid-area: check;
      }
    }
  }
  .c-form01__ttl{
    margin-bottom: 16px;
    display: inline-block;
    @include font_title;
  }
  .c-form01__required{
    display: inline-block;
    margin-left: 8px;
    padding: 2px 5px;
    @include font_title;
    font-size: font-size(12);
    color: #fff;
    vertical-align: 2px;
    background: $clr_primary;
    border-radius: 2px;
  }
  .c-form01__add{
    display: grid;
    grid-template: "txt num" auto ". add" auto ". des" auto /auto 1fr;
    align-items: center;
    gap: 8px 14px;
  }
  .c-form01__add-txt{
    grid-area: txt;
  }
  input[name="postcode"]{
    grid-area: num;
  }
  input[name="address"]{
    grid-area: add;
  }
  input[name="add03"]{
    grid-area: des;
  }
  .c-form01__radio{
    display: flex;
    flex-direction: column;
    gap: 20px;
    span{
      margin-left: 0 !important;
    }
  }
  .c-form01__policy{
    text-align: right;
  }
  .c-form01__policy-link{
    display: inline-block;
    font-size: font-size(12);
    text-decoration: underline;
    &:hover,&:focus-visible{
      text-decoration: none;
    }
  }
  @include mq (medium){
    .c-form01__contents{
      margin: 0;
      border-radius: 10px;
    }
  }
  @include mq (large){
    .c-form01__contents{
      padding: 80px 120px;
    }
    .c-form01__required{
      margin-left: 12px;
      font-size: font-size(13);
    }
    .c-form01__ttl{
      margin-bottom: 20px;
    }
    .c-form01__item{
      &:nth-child(n+2) {
        margin-top: 37px;
      }
    }
    .c-form01__add{
      grid-template: "txt num" ". add" ". des" / auto 1fr;
      align-items: center;
      column-gap: 20px;
    }
    .c-form01__radio{
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px 32px;
    }
  }
}

//c-form01__btn
.c-form01__btn{
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  button{
    position: relative;
    margin: 0 auto;
    padding: 10px 16px 10px 20px;
    width: 180px;
    @include font_base;
    font-weight: 500;
    color: #fff;
    text-align: left;
    background: $clr_primary;
    border-radius: 500px;
    border: 1px solid transparent;
    @include trans-normal;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 16px;
      height: 12px;
      right: 10px;
      top: 40%;
      background: #fff;
      mask: url(../img/common/icon-arw.svg) no-repeat center /contain;
    }
    &:hover,&:focus-visible{
      color: $clr_primary;
      background: #fff;
      border: 1px solid $clr_primary;
      &::after {
        background: $clr_primary;
      }
    }
    &[value="back"]{
      color: $clr_primary;
      border: 1px solid $clr_primary;
      background: #fff;
      &::after {
        background: $clr_primary;
      }
      &:hover,&:focus-visible{
        color: #fff;
        background: $clr_primary;
        &::after {
          background: #fff;
        }
      }
    }
  }
  @include mq (large){
    margin-top: 40px;
    flex-direction: row;
    button{
      padding: 16px 32px;
      width: 250px;
      &::after {
        right: 32px;
      }
      &[value="back"]{
        margin-right: 0;
      }
      &[value="send"]{
        margin-left: 0;
      }
    }
  }
}

.mw_wp_form.mw_wp_form_confirm,
.mw_wp_form.mw_wp_form_complete {
  margin-top: -60px;
  padding-top: 60px;
  @include mq (large){
    margin-top: -150px;
    padding-top: 150px;
  }

}

.p-country-name{
  display: none;
}