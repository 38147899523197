@use '../../fundation/index' as *;

/*  404
================================================ */
.p-page404{
	padding: 50px 0;
	.p-page404__inner{
		@include inner();
	}
	//.p-page404__head{
	//	text-align: center;
	//}
	@include mq (medium){
		padding: 100px 0;
	}
}