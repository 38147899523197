@use '../../fundation/index' as *;

/*  c-cv01
----------------------------------- */
.c-cv01{
  position: relative;
  padding: 100px 0 60px;
  background: $clr_primary;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: url(../img/common/img-cv01_S.png) no-repeat top /contain;
    z-index: -1;
  }
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100vw;
    bottom: 1px;
    mask: url(../img/common/img-deco.svg) no-repeat top center /contain;
    background: $clr_primary;
    translate: 0 100%;
    aspect-ratio: 1920/120;
  }
  .c-cv01__inner{
    @include inner;
    padding-left: 32px;
    padding-right: 32px;
  }
  .c-cv01__ttl{
    color: #fff;
    text-align: center;
  }
  .c-cv01__ttl-en{
    font-weight: 500;
  }
  .c-cv01__ttl-ja{
    margin-top: 20px;
    @include font_title;
    font-size: font-size(26);
  }
  .c-cv01__txt{
    margin-top: 16px;
    color: #fff;
    font-weight: 500;
  }
  .c-cv01__contents{
    display: grid;
    margin-top: 40px;
    gap: 8px;
  }
  .c-cv01__item{
    padding: 24px 20px 20px;
    background: #fff;
    border-radius: 10px;
    &.-line{
      .c-cv01__item-ttl-icon{
        width: 32px;
      }
    }
    &.-mail{
      .c-cv01__item-ttl-icon{
        align-items: center;
        display: inline-flex;
      }
    }
  }
  .c-cv01__item-ttlArea{
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
    gap: 14px;
  }
  .c-cv01__item-ttl{
    @include font_title;
    font-size: font-size(20);
    text-align: center;
  }
  .c-cv01__item-ttl-icon{
    width: 26px;
  }
  .c-cv01__item-txt{
    margin-bottom: 24px;
  }
  .c-cv01__btn-link{
    position: relative;
    display: block;
    padding: 10px 40px 10px 20px;
    background: $clr_primary;
    border-radius: 500px;
    @include trans-normal;
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 16px;
      height: 12px;
      top: 16px;
      right: 30px;
      background: url(../img/common/icon-arw.svg) no-repeat top /contain;
    }
    &:hover,&:focus-visible{
      margin: 0 20px;
      padding: 10px 32px 10px 26px;
    }
  }
  .c-cv01__btn-txt{
    color: #fff;
    font-size: font-size(14);
    font-weight: 500;
    text-align: center;
  }
  .c-cv01__note{
    margin-top: 8px;
    font-size: font-size(12);
    text-align: center;
    color: $clr_txt_gray;
  }
  // .c-cv01__btn-txt-arw{
  //   position: relative;
  //   &::before {
  //     content: '';
  //     position: absolute;
  //     display: block;
  //     width: 16px;
  //     height: 12px;
  //     top: 5px;
  //     right: -30px;
  //     background: url(../img/common/icon-arw.svg) no-repeat top /contain;
  //   }
  // }
  @include mq (large){
    padding: 100px 0 80px;
    &::before {
      background: url(../img/common/img-cv01_L.png) no-repeat right /cover;
    }
    .c-cv01__inner{
      padding-left: 20px;
      padding-right: 20px;
    }
    .c-cv01__ttl{
      text-align: left;
    }
    .c-cv01__ttl-en{
      font-size: font-size(20);
    }
    .c-cv01__ttl-ja{
      margin-top: 12px;
      font-size: font-size(32);
    }
    .c-cv01__contents{
      // grid-template-columns: repeat(3, 1fr);
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
    .c-cv01__item{
      padding: 25px 20px;
      display: grid;
      grid-template-rows: subgrid;
      grid-row: span 3;
      gap: 0;
      &.-line{
        .c-cv01__item-ttl-icon{
          width: 64px;
          height: 64px;
        }
      }
      &.-mail{
        .c-cv01__item-ttl-icon{
          width: 48px;
          height: 48px;
        }
      }
    }
    .c-cv01__item-ttlArea{
      margin-bottom: 32px;
      flex-direction: column;
      gap: 32px;
    }
    .c-cv01__item-ttl-icon{
      width: 40px;
      height: 40px;
    }
    .c-cv01__item-ttl{
      font-size: font-size(24);
    }
    .c-cv01__note{
      font-size: font-size(13);
    }
    .c-cv01__btn-link{
      padding: 16px;
      &::before {
        width: 16px;
        height: 12px;
        top: 22px;
        right: 15px;
      }
      &:hover,&:focus-visible{
        padding: 16px 32px 16px 26px;
      }
    }
    // .c-cv01__btn-txt-arw{
    //   &::before {
    //     top: 7px;
    //     right: -70px;
    //   }
    // }
  }
  @include mq (full){
    .c-cv01__ttl-ja{
      font-size: font-size(48);
    }
    .c-cv01__contents{
      margin-top: 80px;
      gap: 19px;
    }
    .c-cv01__item{
      padding: 45px 60px;
    }
    .c-cv01__btn-link{
      padding: 16px 32px;
      &::before {
        top: 25px;
        right: 32px;
      }
    }
    .c-cv01__btn-txt{
      text-align: left;
      font-size: font-size(17);
    }
  }
}

.c-cv01.-noDeco{
  &::after {
    display: none;
  }
}

/*  c-cv02
----------------------------------- */
.c-cv02{
  display: grid;
  gap: 8px;
  .c-cv02__item{
    padding: 24px 20px 20px;
    background: #fff;
    border-radius: 10px;
    // &.-line{
    //   .c-cv02__item-ttl-icon{
    //     width: 32px;
    //   }
    // }
    &.-mail{
      .c-cv02__item-ttl-icon{
        align-items: center;
        display: inline-flex;
      }
    }
  }
  .c-cv02__item-ttlArea{
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
    gap: 14px;
  }
  .c-cv02__item-ttl{
    @include font_title;
    font-size: font-size(20);
    text-align: center;
  }
  .c-cv02__item-ttl-icon{
    width: 26px;
  }
  .c-cv02__btn-link{
    position: relative;
    display: block;
    padding: 10px 40px 10px 20px;
    background: $clr_primary;
    border-radius: 500px;
    @include trans-normal;
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 16px;
      height: 12px;
      top: 17px;
      right: 30px;
      background: url(../img/common/icon-arw.svg) no-repeat top /contain;
    }
    &:hover,&:focus-visible{
      margin: 0 20px;
      padding: 10px 20px 10px 20px;
    }
  }
  .c-cv02__btn-txt{
    color: #fff;
    font-weight: 500;
    text-align: center;
  }
  .c-cv02__note{
    margin-top: 8px;
    font-size: font-size(12);
    text-align: center;
    color: $clr_txt_gray;
  }
  .c-cv02__item-ttl-txt{
    margin-bottom: 24px;
  }
  @include mq (medium){
    .c-cv02__btn-link{
      &::before {
        top: 18px;
        right: 75px;
      }
    }
  }
  @include mq (large){
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    .c-cv02__item{
      padding: 25px 20px;
      display: grid;
      grid-template-rows: subgrid;
      grid-row: span 3;
      gap: 0;
      // &.-line{
      //   .c-cv02__item-ttl-icon{
      //     width: 56px;
      //   }
      // }
      &.-mail{
        .c-cv02__item-ttl-icon{
          width: 42px;
        }
      }
    }
    .c-cv02__item-ttlArea{
      margin-bottom: 32px;
      flex-direction: column;
      gap: 32px;
    }
    .c-cv02__item-ttl{
      font-size: font-size(24);
    }
    .c-cv02__item-ttl-icon{
      width: 35px;
    }
    .c-cv02__btn-link{
      width: fit-content;
      margin: 0 auto;
      padding: 16px 68px 16px 32px;
      &::before {
        right: 25px;
        top: 25px;
      }
      &:hover,&:focus-visible{
        margin: 0 auto;
        padding: 16px 58px 16px 32px;
      }
    }
    .c-cv02__note{
      font-size: font-size(13);
    }
    .c-cv02__btn-txt{
      font-size: font-size(16);
    }
  }
  @include mq (full){
    // grid-template-columns: repeat(3, 1fr);
    gap: 19px;
    .c-cv02__item{
      padding: 45px 50px;
    }
    .c-cv02__btn-txt{
      text-align: left;
      font-size: font-size(17);
    }
  }
}
.c-cv02.-col2{
  @include mq (large){
    grid-template-columns: auto;
    .c-cv02__item{
      // &.-line{
      //   .c-cv02__item-ttl-icon{
      //     width: 48px;
      //   }
      // }
      &.-tel{
        .c-cv02__item-ttl-icon{
          width: 30px;
        }
        .c-cv02__btn{
          margin: 0 auto;
          width: 240px;
        }
      }
    }
    .c-cv02__item-ttlArea{
      margin-bottom: 24px;
      gap: 24px;
    }
    .c-cv02__item-ttl{
      font-size: font-size(20);
    }
  }
  @include mq (full){
    .c-cv02__item{
      &.-tel{
        padding: 45px 75px;
      }
    }
  }
}


/*  c-cv-company
----------------------------------- */
.c-cv-company{
  padding: 80px 0;
  .c-cv-company__inner{
    @include inner(1060px);
  }
  .c-cv-company__container{
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: 335 / 233;
  }
  .c-cv-company__container-img{
    position: absolute;
    width: 100%;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      border-radius: 10px;
      background-color: rgba(0,0,0,.3);
    }
    img{
      border-radius: 10px;
    }
  }
  .c-cv-company__container-txtArea{
    position: absolute;
    width: max-content;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }
  .c-cv-company__container-ttl,
  .c-cv-company__container-txt{
    text-align: center;
    color: #fff;
  }
  .c-cv-company__container-ttl{
    @include font_title;
    font-size: font-size(26);
  }
  .c-cv-company__container-txt{
    margin-top: 8px;
  }
  .c-cv-company__btn{
    margin-top: 20px;
  }
  @include mq (large){
    padding: 130px 0;
    .c-cv-company__container{
      aspect-ratio: 1060 / 280;
    }
    .c-cv-company__container-ttl{
      font-size: font-size(40);
    }
    .c-cv-company__container-txt{
      font-size: font-size(16);
    }
    .c-cv-company__container-btn{
      margin-top: 20px;
    }
  }
  @include mq (full){
    .c-cv-company__container{
      width: min(1060px,100%);
    }
  }
}