@use '../../fundation/index' as *;

/*  p-message
----------------------------------- */
.p-message{
  padding-top: 80px;
  @include mq (large){
    padding-top: 90px;
  }
}

/*  p-message__intro
----------------------------------- */
.p-message__intro{
  position: relative;
  padding-bottom: 80px;
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100vw;
    bottom: 1px;
    translate: 0 100%;
    background: url(../img/common/img-deco.svg) no-repeat top center / contain;
    z-index: 1;
    aspect-ratio: 1920/120;
  }
  .p-message__intro-inner{
    @include inner(750px);
  }
  .p-message__intro-logo{
    margin: 0 auto;
    width: 143px;
  }
  .p-message__intro-txtWrap{
    margin-top: 31px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .p-message__intro-img{
    margin: 40px auto 0;
    width: 255px;
  }
  @include mq (large){
    padding-bottom: 70px;
    .p-message__intro-logo{
      width: 200px;
    }
    .p-message__intro-txtWrap{
      margin-top: 70px;
      padding-left: 0;
      padding-right: 0;
    }
    .p-message__intro-txt{
      line-height: 2;
      text-align: center;
      font-size: font-size(19);
      &:nth-child(n+2) {
        margin-top: 30px;
      }
    }
    .p-message__intro-img{
      margin: 45px auto 0;
      width: 160px;
    }
  }
}

/*  p-message__contents
----------------------------------- */
.p-message__contents{
  position: relative;
  padding: 102px 0 16px;
  background: $clr_bg;
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100vw;
    bottom: 1px;
    translate: 0 100%;
    mask: url(../img/common/img-deco.svg) no-repeat top center / contain;
    background: $clr_bg;
    aspect-ratio: 1920/120;
  }
  .p-message__contents-inner{
    @include inner;
  }
  .p-message__contents-ttl{
    @include font_title;
    font-size: font-size(22);
    strong{
      color: $clr_primary;
    }
  }
  .p-message__contents-txtWrap{
    margin-top: 24px;
  }
  .p-message__contents-txt{
    &:nth-child(n+2) {
      margin-top: 16px;
    }
  }
  .p-message__contents-name{
    margin-top: 20px;
  }
  .p-message__contents-name-small{
    padding-right: 10px;
    font-size: font-size(13);
  }
  .p-message__contents-img{
    margin-top: 20px;
  }
  @include mq (large){
    padding: 240px 0 270px;
    .p-message__contents-container{
      position: relative;
      width: 660px;
      margin-left: 0;
      z-index: 1;
    }
    .p-message__contents-img{
      position: absolute;
      margin-top: 0;
      bottom: -10px;
      right: 0;
    }
    .p-message__contents-ttl{
      font-size: font-size(32);
    }
    .p-message__contents-txtWrap{
      margin-top: 40px;
    }
    .p-message__contents-txt{
      line-height: 2;
      &:nth-child(n+2) {
        margin-top: 14px;
      }
    }
    .p-message__contents-name{
      margin-top: 40px;
      font-size: font-size(19);
    }
    .p-message__contents-name-small{
      padding-right: 16px;
      font-size: font-size(15);
    }
  }
  @include mq (full){
    .p-message__contents-img{
      bottom: 70px;
    }
  }
}

/*  p-message__company
----------------------------------- */
.p-message__company{
  position: relative;
  padding-top: 102px;
  @include mq (large){
    padding-top: 240px;
  }
}