.u-txt-uppercase {
	text-transform: uppercase;
}
.u-txt-center{
	text-align: center;
}
.u-txt-left{
	text-align: left;
}
.u-txt-right{
	text-align: right;
}