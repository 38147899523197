@use '../../fundation/index' as *;

/*  p-company
----------------------------------- */
.p-company{
  padding-top: 80px;
  .p-company-about{
    padding-bottom: 80px;
  }
  .p-company-about__inner{
    @include inner(960px);
  }
  .p-company-access{
    padding: 80px 0;
    background: $clr_bg;
  }
  .p-company-access__inner{
    @include inner(960px);
  }
  .p-company-access__contents-txt{
    margin-bottom: 40px;
  }
  .p-company-access__contents-map{
    line-height: 1.1;
    iframe{
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
      vertical-align: bottom;
    }
  }
  @include mq (large){
    padding-top: 80px;
    .p-company-about{
      padding-bottom: 130px;
    }
    .p-company-about__table{
      th{
        width: 240px;
      }
    }
    .p-company-access{
      padding: 130px 0;
    }
    .p-company-access__head{
      margin-bottom: 30px;
    }
    .p-company-access__contents-txt{
      margin-bottom: 80px;
      text-align: center;
    }
    .p-company-access__contents-map{
      iframe{
        aspect-ratio: 960/400;
      }
    }
  }
}