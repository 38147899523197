@charset "UTF-8";
/*  color
================================================ */
/*  width
================================================ */
/*  transition
================================================ */
/*  font
================================================ */
/*  vw計算
---------------------------------------- */
/*  reset
================================================ */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  box-sizing: border-box;
}
*::before, *::after {
  box-sizing: border-box;
}

html {
  word-break: break-all;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

img, picture, video, canvas, svg {
  width: 100%;
  vertical-align: bottom;
}

input, button, textarea, select {
  font: inherit;
}

span {
  font-weight: inherit;
}

a {
  color: inherit;
}

a[class] {
  text-decoration: none;
}

mark {
  background-color: #ff9;
  font-weight: bold;
}

blockquote, q {
  quotes: none;
}

ul[class],
ol[class] {
  list-style: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

#root, #__next {
  isolation: isolate;
}

input[type=text], input[type=email], input[type=tel], textarea, button, select, option {
  display: block;
  width: 100%;
  font: inherit;
  outline: none;
  border-radius: 0;
}

textarea {
  resize: vertical;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

summary {
  list-style: none;
  cursor: pointer;
}

summary::-webkit-details-marker {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: inherit;
}

/*  base
================================================ */
html {
  overflow: auto;
  scroll-behavior: smooth;
  scroll-padding-top: 60px;
}
html:not([lang=ja]) {
  word-break: break-word;
}
@media all and ( 1008px <= width ) {
  html {
    scroll-padding-top: 150px;
  }
}

body {
  overflow: hidden;
  min-width: 320px;
  font-feature-settings: "palt";
  color: #2F1908;
  font-family: "Murecho", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  line-height: 1.8;
  letter-spacing: 0.04em;
  font-weight: 400;
  font-size: 1rem;
}
@media all and ( 768px <= width ) {
  body {
    font-size: 1.0625rem;
  }
}
@media all and ( 768px <= width ) {
  body {
    min-width: auto;
  }
}

/*  header
----------------------------------- */
.l-header {
  position: relative;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  z-index: 100;
}
.l-header .l-header__inner {
  position: fixed;
  display: grid;
  padding: 0 20px;
  width: 100%;
  height: 60px;
  align-items: center;
  background: #fff;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.l-header .l-header__logo {
  width: 120px;
}
.l-header .l-header__logo-link {
  display: block;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.l-header .l-header__logo-link:hover, .l-header .l-header__logo-link:focus-visible {
  opacity: 0.5;
}
@media all and ( 1008px <= width ) {
  .l-header {
    height: 150px;
  }
  .l-header .l-header__inner {
    padding: 0;
    height: 149px;
    grid-template: "logo contact" 1fr "menu menu" 1fr/1fr;
  }
  .l-header .l-header__logo {
    margin-left: 24px;
    width: 190px;
    grid-area: logo;
  }
  .l-header .l-header__contact {
    grid-area: contact;
  }
  .l-header .l-header__nav {
    grid-area: menu;
  }
  .l-header .l-header__contact-list {
    display: flex;
    align-items: center;
  }
  .l-header .l-header__contact-tel {
    padding: 9px 28px 9px 30px;
  }
  .l-header .l-header__contact-tel-link {
    position: relative;
    display: block;
    line-height: 1.6;
    letter-spacing: 0.06em;
    font-weight: 700;
    font-size: 1.5rem;
    transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
    transition-property: all;
  }
  .l-header .l-header__contact-tel-link::before {
    content: "";
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 9px;
    left: -30px;
    background: url(../img/common/icon-tel.svg) no-repeat center/contain;
  }
  .l-header .l-header__contact-tel-link:hover, .l-header .l-header__contact-tel-link:focus-visible {
    opacity: 0.5;
  }
  .l-header .l-header__contact-item-txt {
    font-size: 0.8125rem;
    color: #938A83;
  }
  .l-header .l-header__contact-link {
    display: block;
    padding: 25px 28px;
    transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
    transition-property: all;
  }
  .l-header .l-header__contact-link:hover.-mail, .l-header .l-header__contact-link:focus-visible.-mail {
    background: #F5E4C4;
  }
  .l-header .l-header__contact-link:hover.-mail .l-header__contact-link-txt, .l-header .l-header__contact-link:focus-visible.-mail .l-header__contact-link-txt {
    color: #EC6C00;
  }
  .l-header .l-header__contact-link:hover.-mail .l-header__contact-link-txt::before, .l-header .l-header__contact-link:focus-visible.-mail .l-header__contact-link-txt::before {
    background: url(../img/common/icon-mail.svg) no-repeat center/contain;
  }
  .l-header .l-header__contact-link:hover.-contact, .l-header .l-header__contact-link:focus-visible.-contact {
    background: #DCFAE8;
  }
  .l-header .l-header__contact-link:hover.-contact .l-header__contact-link-txt, .l-header .l-header__contact-link:focus-visible.-contact .l-header__contact-link-txt {
    color: #06C755;
  }
  .l-header .l-header__contact-link:hover.-contact .l-header__contact-link-txt::before, .l-header .l-header__contact-link:focus-visible.-contact .l-header__contact-link-txt::before {
    background: url(../img/common/icon-contact-green.svg) no-repeat center/contain;
  }
  .l-header .l-header__contact-link.-mail {
    background: #EC6C00;
  }
  .l-header .l-header__contact-link.-mail .l-header__contact-link-txt::before {
    background: url(../img/common/icon-mail-white.svg) no-repeat center/contain;
  }
  .l-header .l-header__contact-link.-contact {
    background: #06C755;
  }
  .l-header .l-header__contact-link.-contact .l-header__contact-link-txt::before {
    background: url(../img/common/icon-contact.svg) no-repeat center/contain;
  }
  .l-header .l-header__contact-link-txt {
    position: relative;
    padding-left: 40px;
    line-height: 1.6;
    letter-spacing: 0.06em;
    font-weight: 700;
    color: #fff;
    font-size: 1.1875rem;
  }
  .l-header .l-header__contact-link-txt::before {
    content: "";
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
    top: 4px;
    left: 0;
  }
}

/*  drawer
----------------------------------- */
.l-drawer {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  max-height: calc(100vh - 60px);
  background: #fff;
  overflow: auto;
  transform: translateX(120%);
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.l-drawer::-webkit-scrollbar {
  display: none;
}
.l-drawer[aria-hidden=false] {
  transform: translateX(0);
}
.l-drawer .l-drawer__contents {
  padding: 40px;
  background: #F4EDE7;
}
.l-drawer .l-drawer__contents-list-in {
  padding-bottom: 48px;
}
.l-drawer .l-drawer__contents-item {
  border-bottom: 1px solid #DBCDC2;
}
.l-drawer .l-drawer__contents-item:first-child .l-drawer__contents-item-link {
  padding: 0 0 32px 0;
}
.l-drawer .l-drawer__contents-item:nth-child(n+2) .l-drawer__contents-item-link {
  padding: 32px 0;
}
.l-drawer .l-drawer__contents-item.-hasContents {
  padding: 32px 0 24px;
}
.l-drawer .l-drawer__contents-item-link {
  display: block;
  font-size: 1.125rem;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
  font-weight: 500;
}
.l-drawer .l-drawer__contents-item-link:hover, .l-drawer .l-drawer__contents-item-link:focus-visible {
  opacity: 0.5;
}
.l-drawer .l-drawer__contents-item-txt {
  font-size: 1.125rem;
  font-weight: 500;
}
.l-drawer .l-drawer__item-contents {
  padding-top: 32px;
}
.l-drawer .l-drawer__item-contents-item {
  position: relative;
  padding-left: 20px;
}
.l-drawer .l-drawer__item-contents-item::before {
  content: "";
  position: absolute;
  display: block;
  width: 10px;
  height: 3px;
  background: #DBCDC2;
  top: 15px;
  left: 0;
}
.l-drawer .l-drawer__item-contents-item:nth-child(n+2) {
  margin-top: 20px;
}
.l-drawer .l-drawer__item-contents-item-link {
  display: inline-block;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.l-drawer .l-drawer__item-contents-item-link:hover, .l-drawer .l-drawer__item-contents-item-link:focus-visible {
  opacity: 0.5;
}
.l-drawer .l-drawer__contact-item {
  margin: 0 auto;
  width: 260px;
}
.l-drawer .l-drawer__contact-item:nth-child(n+2) {
  margin-top: 10px;
}
.l-drawer .l-drawer__contact-icon {
  display: inline-block;
  margin-right: 12px;
  width: 28px;
  height: 28px;
  vertical-align: middle;
}
.l-drawer .l-drawer__contact-link {
  display: block;
  padding: 18px 20px;
  color: #fff;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  text-align: center;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.l-drawer .l-drawer__contact-link:hover, .l-drawer .l-drawer__contact-link:focus-visible {
  opacity: 0.5;
}
.l-drawer .l-drawer__contact-link.-contact {
  background: #06C755;
}
.l-drawer .l-drawer__contact-link.-contact .l-drawer__contact-icon {
  background: url(../img/common/icon-contact.svg) no-repeat center/contain;
}
.l-drawer .l-drawer__contact-link.-mail {
  background: #EC6C00;
}
.l-drawer .l-drawer__contact-link.-mail .l-drawer__contact-icon {
  background: url(../img/common/icon-mail-white.svg) no-repeat center/contain;
}
.l-drawer .l-drawer__contents-tel {
  margin: 20px auto 0;
  width: 230px;
}
.l-drawer .l-drawer__contents-tel-link {
  position: relative;
  display: block;
  padding-left: 39px;
  color: #2F1908;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.5rem;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.l-drawer .l-drawer__contents-tel-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  top: 3px;
  left: 0;
  background: url(../img/common/icon-tel.svg) no-repeat center/contain;
}
.l-drawer .l-drawer__contents-tel-link:hover, .l-drawer .l-drawer__contents-tel-link:focus-visible {
  opacity: 0.5;
}
.l-drawer .l-drawer__contact-item-txt {
  color: #938A83;
  font-size: 0.8125rem;
  text-align: center;
}
@media all and ( 1008px <= width ) {
  .l-drawer .l-drawer__contents {
    display: none;
  }
}

/*  l-header__btn
----------------------------------- */
.l-header__btnWrap {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 120px;
  height: 60px;
  top: 0;
  right: 0;
}
.l-header__btnWrap .l-header__btn {
  position: relative;
  padding: 7px;
  width: 50%;
}
.l-header__btnWrap .l-header__btn.-contact {
  background: #06C755;
}
.l-header__btnWrap .l-header__btn.-contact .l-header__btn-icon {
  display: block;
  margin: 0 auto;
  width: 26px;
  height: 26px;
  background: url(../img/common/icon-contact.svg) no-repeat center/contain;
}
.l-header__btnWrap .l-header__btn.-contact .l-header__btn-text {
  letter-spacing: -0.04em;
}
.l-header__btnWrap .l-header__btn.-menu {
  background: #EC6C00;
}
.l-header__btnWrap .l-header__btn.-menu .l-header__btn-text {
  padding-top: 6px;
  letter-spacing: -0.1em;
}
.l-header__btnWrap .l-header__btn-text {
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  color: #fff;
}
.l-header__btnWrap .l-header__btn-line {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 26px;
  height: 20px;
}
.l-header__btnWrap .l-header__btn-line::before, .l-header__btnWrap .l-header__btn-line::after {
  content: "";
  position: absolute;
  left: 50%;
  translate: -50% 0;
  width: 100%;
  height: 3px;
  background: #fff;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.l-header__btnWrap .l-header__btn-line::before {
  top: 3px;
}
.l-header__btnWrap .l-header__btn-line::after {
  top: 19px;
}
.l-header__btnWrap .l-header__btn-line-in {
  position: absolute;
  display: block;
  width: 26px;
  height: 3px;
  top: 50%;
  right: 50%;
  translate: 50% 50%;
  background: #fff;
}
.l-header__btnWrap .l-header__btn[aria-expanded=true] .l-header__btn-line::before, .l-header__btnWrap .l-header__btn[aria-expanded=true] .l-header__btn-line::after {
  top: 8px;
}
.l-header__btnWrap .l-header__btn[aria-expanded=true] .l-header__btn-line::before {
  rotate: 45deg;
}
.l-header__btnWrap .l-header__btn[aria-expanded=true] .l-header__btn-line::after {
  rotate: -45deg;
}
.l-header__btnWrap .l-header__btn[aria-expanded=true] .l-header__btn-line-in {
  opacity: 0;
}

/*  l-gnav
----------------------------------- */
@media all and ( 1008px <= width ) {
  .l-gnav {
    background: #fff;
    border: solid #E3E2E0;
    border-width: 1px 0;
  }
  .l-gnav .l-gnav__inner {
    width: min(100%, 1320px);
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media all and ( 1008px <= width ) and (768px <= width < 1320px) {
  .l-gnav .l-gnav__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media all and ( 1008px <= width ) {
  .l-gnav .l-gnav__list {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media all and ( 1008px <= width ) {
  .l-gnav .l-gnav__item {
    padding: 18px 0;
    width: min(320px, 100%);
  }
  .l-gnav .l-gnav__item:hover .l-gnav__item-link,
.l-gnav .l-gnav__item:hover .l-gnav__item-trigger, .l-gnav .l-gnav__item:focus-visible .l-gnav__item-link,
.l-gnav .l-gnav__item:focus-visible .l-gnav__item-trigger {
    color: #EC6C00;
  }
  .l-gnav .l-gnav__item:nth-child(2) .l-gnav__item-text {
    border-width: 0 1px 0 0;
  }
}
@media all and ( 1008px <= width ) {
  .l-gnav .l-gnav__item-text {
    text-align: center;
    border: solid #E3E2E0;
    border-width: 0 1px;
  }
}
@media all and ( 1008px <= width ) {
  .l-gnav .l-gnav__item-link,
.l-gnav .l-gnav__item-trigger {
    display: block;
    font-size: 1.0625rem;
    font-weight: 500;
    transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
    transition-property: all;
    pointer-events: auto;
    text-align: center;
    cursor: pointer;
  }
}
@media all and ( 1008px <= width ) {
  .l-gnav .l-gnav__item-trigger {
    background: transparent;
  }
}
@media all and ( 1008px <= width ) {
  .l-gnav .l-gnav__item-trigger-icon {
    display: inline-block;
    margin-left: 10px;
    width: 12px;
    height: 8px;
    cursor: pointer;
    vertical-align: 4px;
    background: url(../img/common/icon-menu.svg) no-repeat center/contain;
  }
}
@media all and ( 1008px <= width ) {
  .l-gnav .l-gnav__item-contents {
    position: absolute;
    width: 320px;
    top: 80px;
    pointer-events: none;
    transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
    transition-property: all;
    opacity: 0;
  }
}
@media all and ( 1008px <= width ) {
  .l-gnav .l-gnav__item-contents-list {
    padding: 24px 32px;
    border: 1px solid #E3E2E0;
    background-color: #fff;
  }
}
@media all and ( 1008px <= width ) {
  .l-gnav .l-gnav__item-contents-item:first-child {
    padding: 0 0 24px;
  }
  .l-gnav .l-gnav__item-contents-item:nth-child(n+2) {
    padding: 24px 0;
    border-top: 1px solid #E3E2E0;
  }
  .l-gnav .l-gnav__item-contents-item:last-child {
    padding: 24px 0 0;
  }
}
@media all and ( 1008px <= width ) {
  .l-gnav .l-gnav__item-contents-item-link {
    display: block;
    padding: 7px 0;
    transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
    transition-property: all;
  }
  .l-gnav .l-gnav__item-contents-item-link:hover, .l-gnav .l-gnav__item-contents-item-link:focus-visible {
    color: #EC6C00;
  }
}
@media all and ( 1320px < width ) {
  .l-gnav .l-gnav__item-trigger::before {
    right: 80px;
  }
}

/*  animation
---------------------------------------- */
@media all and ( 1008px <= width ) {
  .js-header {
    transition: none;
  }
  .js-header .l-header__btn {
    display: none;
  }
  .js-header.is-hidden {
    position: relative;
    opacity: 0;
    pointer-events: none;
    transition: translate 0.5s ease-in-out;
    width: 100%;
    margin-right: 0;
    transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
    transition-property: all;
  }
  .js-header.is-active {
    opacity: 1;
    pointer-events: auto;
    width: 100%;
    margin-right: 0;
  }
  .js-header.is-active .l-header__inner {
    padding-right: 0;
    translate: 0 0;
  }
  .js-header.is-active .l-gnav__item-trigger,
.js-header.is-active .l-gnav__item-link {
    color: #2F1908;
  }
  .js-header.is-active .l-gnav__btn-link {
    display: grid;
    place-content: center;
    border: 1px solid #EC6C00;
    background: #EC6C00;
    height: var(--headerH);
  }
}
@media all and ( 1320px < width ) {
  .js-header.is-active .l-header__inner {
    padding-right: var(--headerH);
  }
  .js-header.is-active .l-header__btn {
    display: block;
  }
}

.js-gnav-toggle .js-gnav-toggle__trigger.is-active {
  color: #EC6C00;
}
.js-gnav-toggle .js-gnav-toggle__trigger.is-active .l-gnav__item-trigger-icon {
  rotate: -180deg;
}
.js-gnav-toggle .js-gnav-toggle__contents {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.js-gnav-toggle .js-gnav-toggle__contents.is-active {
  pointer-events: auto;
  opacity: 1;
}

/*  footer
----------------------------------- */
.l-footer {
  padding-top: 102px;
}
.l-footer.-hasBorder {
  padding-top: 80px;
  border-top: 1px solid #E3E2E0;
}
.l-footer.-hasBorder::before {
  display: none;
}
.l-footer .l-footer__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .l-footer .l-footer__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.l-footer .l-footer__contents-info-logo {
  margin: 0 auto;
  width: 160px;
}
.l-footer .l-footer__contents-info-logo-link {
  display: block;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.l-footer .l-footer__contents-info-logo-link:hover, .l-footer .l-footer__contents-info-logo-link:focus-visible {
  opacity: 0.5;
}
.l-footer .l-footer__contents-info-txt {
  padding-top: 16px;
  text-align: center;
  line-height: 1.5;
  font-size: 0.8125rem;
}
.l-footer .l-footer__privacy {
  padding-top: 40px;
  text-align: center;
}
.l-footer .l-footer__privacy-link {
  display: inline-block;
  font-size: 0.75rem;
  color: #938A83;
  text-align: center;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.l-footer .l-footer__privacy-link:hover, .l-footer .l-footer__privacy-link:focus-visible {
  opacity: 0.5;
}
.l-footer .l-footer__bottomWrap {
  margin-top: 70px;
  padding: 16px 0;
  border-top: 1px solid #E3E2E0;
}
.l-footer .l-footer__bottom-inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .l-footer .l-footer__bottom-inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.l-footer .l-footer__bottom-copy {
  text-align: center;
  color: #938A83;
  font-size: 0.75rem;
}
.l-footer .l-footer__bottom-privacy-link {
  display: inline-block;
}
@media all and ( 1008px <= width ) {
  .l-footer {
    padding-top: 270px;
  }
  .l-footer .l-footer__contents {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  .l-footer .l-footer__contents-nav {
    display: flex;
    gap: 40px;
  }
  .l-footer .l-footer__contents-info-logo {
    margin: auto 0;
    width: 295px;
  }
  .l-footer .l-footer__contents-info-txt {
    padding-top: 32px;
    text-align: left;
  }
  .l-footer .l-footer__contents-info-txt-name,
.l-footer .l-footer__contents-info-txt-add {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
  .l-footer .l-footer__contents-nav-item:nth-child(n+2) {
    padding-top: 24px;
  }
  .l-footer .l-footer__contents-nav-item-link,
.l-footer .l-footer__contents-nav-item-sub-item-link {
    display: inline-block;
    font-size: 0.9375rem;
    transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
    transition-property: all;
  }
  .l-footer .l-footer__contents-nav-item-link:hover, .l-footer .l-footer__contents-nav-item-link:focus-visible,
.l-footer .l-footer__contents-nav-item-sub-item-link:hover,
.l-footer .l-footer__contents-nav-item-sub-item-link:focus-visible {
    opacity: 0.5;
  }
  .l-footer .l-footer__contents-nav-item-title {
    font-size: 0.9375rem;
  }
  .l-footer .l-footer__contents-nav-item-sub {
    padding-top: 20px;
  }
  .l-footer .l-footer__contents-nav-item-sub-item {
    position: relative;
    padding-left: 16px;
    font-size: 0.875rem;
  }
  .l-footer .l-footer__contents-nav-item-sub-item::before {
    content: "";
    position: absolute;
    display: block;
    width: 8px;
    height: 2px;
    top: 12px;
    left: 0;
    background: #E3E2E0;
  }
  .l-footer .l-footer__contents-nav-item-sub-item:nth-child(n+2) {
    margin-top: 8px;
  }
  .l-footer .l-footer__bottomWrap {
    margin-top: 120px;
    padding: 20px 0;
  }
  .l-footer .l-footer__bottom-contents {
    display: flex;
  }
  .l-footer .l-footer__bottom-copy,
.l-footer .l-footer__bottom-privacy {
    width: 50%;
  }
  .l-footer .l-footer__bottom-copy {
    text-align: left;
  }
  .l-footer .l-footer__bottom-privacy {
    display: inline-block;
    font-size: 0.75rem;
    color: #938A83;
    text-align: right;
    transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
    transition-property: all;
  }
  .l-footer .l-footer__bottom-privacy:hover, .l-footer .l-footer__bottom-privacy:focus-visible {
    opacity: 0.5;
  }
}
@media all and ( 1320px < width ) {
  .l-footer .l-footer__contents-nav {
    gap: 80px;
  }
}

/*  c-head01
----------------------------------- */
.c-head01 {
  margin-bottom: 40px;
}
.c-head01 .c-head01__icon {
  margin: 0 auto;
  width: 27px;
}
.c-head01 .c-head01__ttl {
  padding-top: 14px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.625rem;
  text-align: center;
}
@media all and ( 1008px <= width ) {
  .c-head01 {
    margin-bottom: 80px;
  }
  .c-head01 .c-head01__icon {
    width: 36px;
  }
  .c-head01 .c-head01__ttl {
    padding-top: 24px;
    font-size: 2.5rem;
  }
}

/*  c-head02
----------------------------------- */
.c-head02 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #DBCDC2;
}
.c-head02 .c-head02__ttl {
  position: relative;
  padding-left: 21px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.375rem;
}
.c-head02 .c-head02__ttl::before {
  content: "";
  position: absolute;
  display: block;
  width: 5px;
  height: 100%;
  top: 0;
  left: 0;
  background: #EC6C00;
}
.c-head02 .c-head02__small {
  display: block;
  font-size: 1rem;
}
@media all and ( 1008px <= width ) {
  .c-head02 {
    margin-bottom: 32px;
    padding-bottom: 18px;
  }
  .c-head02 .c-head02__ttl {
    padding-left: 30px;
    font-size: 1.75rem;
  }
  .c-head02 .c-head02__ttl::before {
    width: 6px;
    top: 2px;
  }
}

/*  c-btn01
----------------------------------- */
.c-btn01 {
  margin-top: 32px;
  text-align: center;
}
.c-btn01 .c-btn01__link {
  position: relative;
  display: inline-block;
  padding: 10px 35px 10px 20px;
  width: fit-content;
  background: #EC6C00;
  border-radius: 500px;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.c-btn01 .c-btn01__link::before {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 8px;
  top: 18px;
  right: 10px;
  mask: url(../img/common/icon-arw.svg) no-repeat top/contain;
  background: #fff;
}
.c-btn01 .c-btn01__link:hover, .c-btn01 .c-btn01__link:focus-visible {
  padding: 10px 30px 10px 15px;
  margin: 0 10px;
}
.c-btn01 .c-btn01__link:hover .js-mouseStalker__contents, .c-btn01 .c-btn01__link:focus-visible .js-mouseStalker__contents {
  background: #4B74C3;
}
.c-btn01 .c-btn01__txt {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
}
@media all and ( 1008px <= width ) {
  .c-btn01 {
    margin-top: 40px;
  }
  .c-btn01 .c-btn01__link {
    padding: 16px 88px 16px 32px;
  }
  .c-btn01 .c-btn01__link::before {
    width: 16px;
    height: 12px;
    right: 32px;
    top: 25px;
  }
  .c-btn01 .c-btn01__link:hover, .c-btn01 .c-btn01__link:focus-visible {
    padding: 16px 70px 16px 26px;
  }
  .c-btn01 .c-btn01__txt {
    font-size: 1.0625rem;
  }
}

.c-btn01.-bgWhite .c-btn01__link {
  background: #fff;
}
.c-btn01.-bgWhite .c-btn01__link::before {
  background: #2F1908;
}
.c-btn01.-bgWhite .c-btn01__link:hover, .c-btn01.-bgWhite .c-btn01__link:focus-visible {
  background: #EC6C00;
}
.c-btn01.-bgWhite .c-btn01__link:hover::before, .c-btn01.-bgWhite .c-btn01__link:focus-visible::before {
  background: #fff;
}
.c-btn01.-bgWhite .c-btn01__link:hover .c-btn01__txt, .c-btn01.-bgWhite .c-btn01__link:focus-visible .c-btn01__txt {
  color: #fff;
}
.c-btn01.-bgWhite .c-btn01__txt {
  color: #2F1908;
}

@media all and ( 1008px <= width ) {
  .c-btn01.-pcLeft {
    text-align: left;
  }
}

@media all and ( 1008px <= width ) {
  .c-btn01.-pcRight {
    text-align: right;
  }
}

.js-mouseStalker {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1px;
  height: 1px;
  top: 50%;
  left: 50%;
  pointer-events: none;
  z-index: 1000;
}

.js-mouseStalker.is-active .js-mouseStalker__contents {
  background: #fff;
  box-shadow: 0px 0px 60px 10px #fff;
  filter: blur(10px);
  opacity: 0.4;
}

.js-mouseStalker__contents {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  flex-shrink: 0;
}

/*  c-btn02
----------------------------------- */
.c-btn02 .c-btn02__link {
  display: block;
  padding: 14px;
  border: 1px solid #DBCDC2;
  border-radius: 500px;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.c-btn02 .c-btn02__link:hover, .c-btn02 .c-btn02__link:focus-visible {
  background: #EC6C00;
}
.c-btn02 .c-btn02__link:hover .c-btn02__txt, .c-btn02 .c-btn02__link:focus-visible .c-btn02__txt {
  color: #fff;
}
.c-btn02 .c-btn02__txt {
  text-align: center;
  color: #EC6C00;
  font-size: 0.9375rem;
  font-weight: 500;
}
@media all and ( 1008px <= width ) {
  .c-btn02 .c-btn02__link {
    padding: 10px;
  }
}

/*  c-text-note01
----------------------------------- */
.c-text-note01 {
  padding-left: 16px;
  text-indent: -17px;
}
.c-text-note01 .c-text-note01__item {
  color: #938A83;
  font-size: 0.8125rem;
}
.c-text-note01 .c-text-note01__item:nth-child(n+2) {
  margin-top: 2px;
}
@media all and ( 1008px <= width ) {
  .c-text-note01 .c-text-note01__item {
    font-size: 0.9375rem;
  }
}

/*  c-list01
----------------------------------- */
.c-list01 .c-list01__item {
  position: relative;
  padding-left: 16px;
}
.c-list01 .c-list01__item::before {
  content: "";
  position: absolute;
  display: block;
  width: 6px;
  height: 2px;
  top: 15px;
  left: 0;
  background: #2F1908;
}
.c-list01 .c-list01__item:nth-child(n+2) {
  margin-top: 6px;
}
@media all and ( 1008px <= width ) {
  .c-list01 .c-list01__item::before {
    width: 8px;
    height: 3px;
  }
}

/*  c-cv01
----------------------------------- */
.c-cv01 {
  position: relative;
  padding: 100px 0 60px;
  background: #EC6C00;
  z-index: 1;
}
.c-cv01::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: url(../img/common/img-cv01_S.png) no-repeat top/contain;
  z-index: -1;
}
.c-cv01::after {
  content: "";
  position: absolute;
  display: block;
  width: 100vw;
  bottom: 1px;
  mask: url(../img/common/img-deco.svg) no-repeat top center/contain;
  background: #EC6C00;
  translate: 0 100%;
  aspect-ratio: 1920/120;
}
.c-cv01 .c-cv01__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-left: 32px;
  padding-right: 32px;
}
@media all and (768px <= width < 1320px) {
  .c-cv01 .c-cv01__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.c-cv01 .c-cv01__ttl {
  color: #fff;
  text-align: center;
}
.c-cv01 .c-cv01__ttl-en {
  font-weight: 500;
}
.c-cv01 .c-cv01__ttl-ja {
  margin-top: 20px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.625rem;
}
.c-cv01 .c-cv01__txt {
  margin-top: 16px;
  color: #fff;
  font-weight: 500;
}
.c-cv01 .c-cv01__contents {
  display: grid;
  margin-top: 40px;
  gap: 8px;
}
.c-cv01 .c-cv01__item {
  padding: 24px 20px 20px;
  background: #fff;
  border-radius: 10px;
}
.c-cv01 .c-cv01__item.-line .c-cv01__item-ttl-icon {
  width: 32px;
}
.c-cv01 .c-cv01__item.-mail .c-cv01__item-ttl-icon {
  align-items: center;
  display: inline-flex;
}
.c-cv01 .c-cv01__item-ttlArea {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  gap: 14px;
}
.c-cv01 .c-cv01__item-ttl {
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
}
.c-cv01 .c-cv01__item-ttl-icon {
  width: 26px;
}
.c-cv01 .c-cv01__item-txt {
  margin-bottom: 24px;
}
.c-cv01 .c-cv01__btn-link {
  position: relative;
  display: block;
  padding: 10px 40px 10px 20px;
  background: #EC6C00;
  border-radius: 500px;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.c-cv01 .c-cv01__btn-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 16px;
  height: 12px;
  top: 16px;
  right: 30px;
  background: url(../img/common/icon-arw.svg) no-repeat top/contain;
}
.c-cv01 .c-cv01__btn-link:hover, .c-cv01 .c-cv01__btn-link:focus-visible {
  margin: 0 20px;
  padding: 10px 32px 10px 26px;
}
.c-cv01 .c-cv01__btn-txt {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
}
.c-cv01 .c-cv01__note {
  margin-top: 8px;
  font-size: 0.75rem;
  text-align: center;
  color: #938A83;
}
@media all and ( 1008px <= width ) {
  .c-cv01 {
    padding: 100px 0 80px;
  }
  .c-cv01::before {
    background: url(../img/common/img-cv01_L.png) no-repeat right/cover;
  }
  .c-cv01 .c-cv01__inner {
    padding-left: 20px;
    padding-right: 20px;
  }
  .c-cv01 .c-cv01__ttl {
    text-align: left;
  }
  .c-cv01 .c-cv01__ttl-en {
    font-size: 1.25rem;
  }
  .c-cv01 .c-cv01__ttl-ja {
    margin-top: 12px;
    font-size: 2rem;
  }
  .c-cv01 .c-cv01__contents {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .c-cv01 .c-cv01__item {
    padding: 25px 20px;
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 3;
    gap: 0;
  }
  .c-cv01 .c-cv01__item.-line .c-cv01__item-ttl-icon {
    width: 64px;
    height: 64px;
  }
  .c-cv01 .c-cv01__item.-mail .c-cv01__item-ttl-icon {
    width: 48px;
    height: 48px;
  }
  .c-cv01 .c-cv01__item-ttlArea {
    margin-bottom: 32px;
    flex-direction: column;
    gap: 32px;
  }
  .c-cv01 .c-cv01__item-ttl-icon {
    width: 40px;
    height: 40px;
  }
  .c-cv01 .c-cv01__item-ttl {
    font-size: 1.5rem;
  }
  .c-cv01 .c-cv01__note {
    font-size: 0.8125rem;
  }
  .c-cv01 .c-cv01__btn-link {
    padding: 16px;
  }
  .c-cv01 .c-cv01__btn-link::before {
    width: 16px;
    height: 12px;
    top: 22px;
    right: 15px;
  }
  .c-cv01 .c-cv01__btn-link:hover, .c-cv01 .c-cv01__btn-link:focus-visible {
    padding: 16px 32px 16px 26px;
  }
}
@media all and ( 1320px < width ) {
  .c-cv01 .c-cv01__ttl-ja {
    font-size: 3rem;
  }
  .c-cv01 .c-cv01__contents {
    margin-top: 80px;
    gap: 19px;
  }
  .c-cv01 .c-cv01__item {
    padding: 45px 60px;
  }
  .c-cv01 .c-cv01__btn-link {
    padding: 16px 32px;
  }
  .c-cv01 .c-cv01__btn-link::before {
    top: 25px;
    right: 32px;
  }
  .c-cv01 .c-cv01__btn-txt {
    text-align: left;
    font-size: 1.0625rem;
  }
}

.c-cv01.-noDeco::after {
  display: none;
}

/*  c-cv02
----------------------------------- */
.c-cv02 {
  display: grid;
  gap: 8px;
}
.c-cv02 .c-cv02__item {
  padding: 24px 20px 20px;
  background: #fff;
  border-radius: 10px;
}
.c-cv02 .c-cv02__item.-mail .c-cv02__item-ttl-icon {
  align-items: center;
  display: inline-flex;
}
.c-cv02 .c-cv02__item-ttlArea {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  gap: 14px;
}
.c-cv02 .c-cv02__item-ttl {
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
}
.c-cv02 .c-cv02__item-ttl-icon {
  width: 26px;
}
.c-cv02 .c-cv02__btn-link {
  position: relative;
  display: block;
  padding: 10px 40px 10px 20px;
  background: #EC6C00;
  border-radius: 500px;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.c-cv02 .c-cv02__btn-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 16px;
  height: 12px;
  top: 17px;
  right: 30px;
  background: url(../img/common/icon-arw.svg) no-repeat top/contain;
}
.c-cv02 .c-cv02__btn-link:hover, .c-cv02 .c-cv02__btn-link:focus-visible {
  margin: 0 20px;
  padding: 10px 20px 10px 20px;
}
.c-cv02 .c-cv02__btn-txt {
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.c-cv02 .c-cv02__note {
  margin-top: 8px;
  font-size: 0.75rem;
  text-align: center;
  color: #938A83;
}
.c-cv02 .c-cv02__item-ttl-txt {
  margin-bottom: 24px;
}
@media all and ( 768px <= width ) {
  .c-cv02 .c-cv02__btn-link::before {
    top: 18px;
    right: 75px;
  }
}
@media all and ( 1008px <= width ) {
  .c-cv02 {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .c-cv02 .c-cv02__item {
    padding: 25px 20px;
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 3;
    gap: 0;
  }
  .c-cv02 .c-cv02__item.-mail .c-cv02__item-ttl-icon {
    width: 42px;
  }
  .c-cv02 .c-cv02__item-ttlArea {
    margin-bottom: 32px;
    flex-direction: column;
    gap: 32px;
  }
  .c-cv02 .c-cv02__item-ttl {
    font-size: 1.5rem;
  }
  .c-cv02 .c-cv02__item-ttl-icon {
    width: 35px;
  }
  .c-cv02 .c-cv02__btn-link {
    width: fit-content;
    margin: 0 auto;
    padding: 16px 68px 16px 32px;
  }
  .c-cv02 .c-cv02__btn-link::before {
    right: 25px;
    top: 25px;
  }
  .c-cv02 .c-cv02__btn-link:hover, .c-cv02 .c-cv02__btn-link:focus-visible {
    margin: 0 auto;
    padding: 16px 58px 16px 32px;
  }
  .c-cv02 .c-cv02__note {
    font-size: 0.8125rem;
  }
  .c-cv02 .c-cv02__btn-txt {
    font-size: 1rem;
  }
}
@media all and ( 1320px < width ) {
  .c-cv02 {
    gap: 19px;
  }
  .c-cv02 .c-cv02__item {
    padding: 45px 50px;
  }
  .c-cv02 .c-cv02__btn-txt {
    text-align: left;
    font-size: 1.0625rem;
  }
}

@media all and ( 1008px <= width ) {
  .c-cv02.-col2 {
    grid-template-columns: auto;
  }
  .c-cv02.-col2 .c-cv02__item.-tel .c-cv02__item-ttl-icon {
    width: 30px;
  }
  .c-cv02.-col2 .c-cv02__item.-tel .c-cv02__btn {
    margin: 0 auto;
    width: 240px;
  }
  .c-cv02.-col2 .c-cv02__item-ttlArea {
    margin-bottom: 24px;
    gap: 24px;
  }
  .c-cv02.-col2 .c-cv02__item-ttl {
    font-size: 1.25rem;
  }
}
@media all and ( 1320px < width ) {
  .c-cv02.-col2 .c-cv02__item.-tel {
    padding: 45px 75px;
  }
}

/*  c-cv-company
----------------------------------- */
.c-cv-company {
  padding: 80px 0;
}
.c-cv-company .c-cv-company__inner {
  width: min(100%, 1100px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .c-cv-company .c-cv-company__inner {
    width: min(100%, 1260px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.c-cv-company .c-cv-company__container {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 335/233;
}
.c-cv-company .c-cv-company__container-img {
  position: absolute;
  width: 100%;
}
.c-cv-company .c-cv-company__container-img::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}
.c-cv-company .c-cv-company__container-img img {
  border-radius: 10px;
}
.c-cv-company .c-cv-company__container-txtArea {
  position: absolute;
  width: max-content;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}
.c-cv-company .c-cv-company__container-ttl,
.c-cv-company .c-cv-company__container-txt {
  text-align: center;
  color: #fff;
}
.c-cv-company .c-cv-company__container-ttl {
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.625rem;
}
.c-cv-company .c-cv-company__container-txt {
  margin-top: 8px;
}
.c-cv-company .c-cv-company__btn {
  margin-top: 20px;
}
@media all and ( 1008px <= width ) {
  .c-cv-company {
    padding: 130px 0;
  }
  .c-cv-company .c-cv-company__container {
    aspect-ratio: 1060/280;
  }
  .c-cv-company .c-cv-company__container-ttl {
    font-size: 2.5rem;
  }
  .c-cv-company .c-cv-company__container-txt {
    font-size: 1rem;
  }
  .c-cv-company .c-cv-company__container-btn {
    margin-top: 20px;
  }
}
@media all and ( 1320px < width ) {
  .c-cv-company .c-cv-company__container {
    width: min(1060px, 100%);
  }
}

/*  c-accordion01
----------------------------------- */
.c-accordion01 .c-accordion01__item {
  background: #fff;
  border-radius: 10px;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.c-accordion01 .c-accordion01__item:nth-child(n+2) {
  margin-top: 16px;
}
.c-accordion01 .c-accordion01__item:hover .c-accordion01__title, .c-accordion01 .c-accordion01__item:focus-visible .c-accordion01__title {
  color: #EC6C00;
}
.c-accordion01 .c-accordion01__titleWrap {
  position: relative;
  padding: 20px 54px 20px 25px;
  cursor: pointer;
}
.c-accordion01 .c-accordion01__titleWrap::before, .c-accordion01 .c-accordion01__titleWrap::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 24px;
  translate: 0 -50%;
  width: 14px;
  height: 2px;
  background: #DBCDC2;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.c-accordion01 .c-accordion01__titleWrap::after {
  rotate: -90deg;
}
.c-accordion01 .c-accordion01__title {
  position: relative;
  padding-left: 30px;
  font-size: 1.125rem;
  font-weight: 500;
}
.c-accordion01 .c-accordion01__title::before {
  content: "Q";
  position: absolute;
  display: block;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.5rem;
  color: #EC6C00;
  top: -2px;
  left: 0;
}
.c-accordion01 .c-accordion01__contents {
  position: relative;
  height: fit-content;
  overflow: hidden;
}
.c-accordion01 .c-accordion01__contents::before {
  content: "";
  position: absolute;
  width: calc(100% - 48px);
  height: 1px;
  top: 0;
  left: 50%;
  translate: -50% 0;
  background: #DBCDC2;
}
.c-accordion01 .c-accordion01__contents__inner {
  padding: 20px 32px;
}
.c-accordion01 .c-accordion01__item[open] .c-accordion01__titleWrap::after {
  rotate: 0deg;
}
.c-accordion01 .c-accordion01__item[open] .c-accordion01__title {
  color: #EC6C00;
}
@media all and ( 1008px <= width ) {
  .c-accordion01 .c-accordion01__titleWrap {
    padding: 24px 40px;
  }
  .c-accordion01 .c-accordion01__titleWrap::before, .c-accordion01 .c-accordion01__titleWrap::after {
    right: 40px;
  }
  .c-accordion01 .c-accordion01__title {
    font-size: 1.1875rem;
  }
  .c-accordion01 .c-accordion01__contents::before {
    width: calc(100% - 80px);
  }
  .c-accordion01 .c-accordion01__contents__inner {
    padding: 24px 40px 32px;
  }
}

/*  c-modal01
----------------------------------- */
.c-modal01 {
  position: relative;
  display: block;
}
.c-modal01 .c-modal01__contents {
  position: absolute;
  margin-top: 10px;
  padding: 10px 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  background: #fff;
  opacity: 0;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.c-modal01 .c-modal01__contents.is-active {
  opacity: 1;
}
.c-modal01 .c-modal01__contents-ttl {
  color: #2F1908;
  font-size: 0.9375rem;
  font-weight: 500;
  text-indent: 0;
}
.c-modal01 .c-modal01__contents-table {
  margin-top: 10px;
}
.c-modal01 .c-modal01__contents-table table {
  table-layout: fixed;
  border: 1px solid #DBCDC2;
}
.c-modal01 .c-modal01__contents-table th, .c-modal01 .c-modal01__contents-table td {
  display: block;
  padding: 20px;
  width: 100%;
  color: #2F1908;
}
.c-modal01 .c-modal01__contents-table th {
  font-weight: 400;
  border-bottom: 1px solid #DBCDC2;
  background: #E3E2E0;
}
@media all and ( 1008px <= width ) {
  .c-modal01 .c-modal01__contents {
    padding: 20px 24px;
    width: min(620px, 100%);
    left: 19%;
  }
  .c-modal01 .c-modal01__contents-table {
    margin-top: 14px;
  }
  .c-modal01 .c-modal01__contents-table tr:nth-child(n+2) {
    border-top: 1px solid #DBCDC2;
  }
  .c-modal01 .c-modal01__contents-table th, .c-modal01 .c-modal01__contents-table td {
    display: table-cell;
    padding: 14px 16px;
  }
  .c-modal01 .c-modal01__contents-table th {
    width: 207px;
  }
}

/*  js-fade-wrapper
----------------------------------- */
.js-fade-wrapper .js-fade-elm {
  overflow: hidden;
  display: block;
  text-align: center;
}
.js-fade-wrapper .js-fade-elm .js-fade-elm__in {
  display: inline-block;
  vertical-align: bottom;
  translate: 0 100%;
  opacity: 0;
  transition: translate 1s 0.3s cubic-bezier(0, 0, 0, 1), opacity 0.4s linear;
}
.js-fade-wrapper.is-active .js-fade-elm__in {
  translate: 0 0;
  opacity: 1;
}

/*  c-layout-info
----------------------------------- */
.c-layout-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.c-layout-info .c-layout-info__item-link {
  display: grid;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.c-layout-info .c-layout-info__item-link:hover, .c-layout-info .c-layout-info__item-link:focus-visible {
  color: #EC6C00;
}
.c-layout-info .c-layout-info__item-img img {
  border-radius: 10px 10px 0 0;
}
.c-layout-info .c-layout-info__item-ttlArea {
  padding: 20px 24px;
  border-radius: 0 0 10px 10px;
  background: #F5F5F5;
  border: solid #E3E2E0;
  border-width: 0 1px 1px;
}
.c-layout-info .c-layout-info__item-ttl {
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
}
@media all and ( 1008px <= width ) {
  .c-layout-info {
    flex-direction: row;
    gap: 40px;
  }
  .c-layout-info .c-layout-info__item {
    display: grid;
    width: calc(50% - 20px);
  }
  .c-layout-info .c-layout-info__item-link {
    grid-template-rows: auto 1fr;
  }
  .c-layout-info .c-layout-info__item-ttlArea {
    padding: 32px;
  }
  .c-layout-info .c-layout-info__item-ttl {
    font-size: 1.1875rem;
  }
}

/*  c-layout-step01
----------------------------------- */
.c-layout-step01 {
  display: grid;
  justify-content: center;
  gap: 16px;
}
.c-layout-step01 .c-layout-step01__item {
  padding: 20px 16px 16px 16px;
  background: #DCE6F4;
  border-radius: 10px;
}
.c-layout-step01 .c-layout-step01__num {
  text-align: center;
  font-size: 0.75rem;
  color: #4B74C3;
}
.c-layout-step01 .c-layout-step01__icon {
  margin: 8px auto 0;
  width: 32px;
}
.c-layout-step01 .c-layout-step01__ttl {
  margin-top: 12px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
}
.c-layout-step01 .c-layout-step01__child {
  margin-top: 24px;
  padding: 24px;
  background: #fff;
  border-radius: 10px;
}
@media all and ( 1008px <= width ) {
  .c-layout-step01 {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
  .c-layout-step01 .c-layout-step01__item {
    display: grid;
    padding: 32px 16px 16px;
    grid-template-rows: subgrid;
    grid-row: span 4;
    gap: 0;
  }
  .c-layout-step01 .c-layout-step01__icon {
    display: inline-flex;
    margin-top: 8px;
    width: 34px;
    align-items: center;
  }
  .c-layout-step01 .c-layout-step01__ttl {
    margin-top: 20px;
    font-size: 1.5rem;
  }
  .c-layout-step01 .c-layout-step01__child {
    margin-top: 32px;
    padding: 32px 40px;
  }
}
@media all and ( 1320px < width ) {
  .c-layout-step01 {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}

@media all and ( 1008px <= width ) {
  .c-layout-step01.-col2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

/*  c-hero
---------------------------------------- */
.c-hero01 {
  position: relative;
  padding: 70px 0;
}
.c-hero01::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #F5F5F5;
  z-index: -1;
}
.c-hero01.-noPb {
  padding: 70px 0 0;
}
.c-hero01.-noPb::before {
  height: 205px;
}
.c-hero01 .c-hero01__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .c-hero01 .c-hero01__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.c-hero01 .c-hero01__text {
  text-align: center;
}
.c-hero01 .c-hero01__text-en {
  color: #938A83;
  font-size: 0.8125rem;
  text-transform: capitalize;
}
.c-hero01 .c-hero01__text-ja {
  margin-top: 10px;
  font-size: 1.5rem;
  vertical-align: 0.02em;
  font-weight: 700;
}
.c-hero01 .c-hero01__contents {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding: 24px;
  width: 100%;
  background: #fff;
  border: 1px solid #E3E2E0;
  border-radius: 10px;
}
.c-hero01 .c-hero01__contents-item {
  display: flex;
  padding: 16px 0;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #E3E2E0;
}
.c-hero01 .c-hero01__contents-item:first-child {
  padding: 0 0 16px 0;
}
.c-hero01 .c-hero01__contents-item:last-child {
  padding: 16px 0 0 0;
  border-bottom: none;
}
.c-hero01 .c-hero01__contents-item-img {
  width: 60px;
}
.c-hero01 .c-hero01__contents-item-txt {
  width: calc(100% - 80px);
  font-size: 0.875rem;
  line-height: 1.6;
}
@media all and ( 1008px <= width ) {
  .c-hero01 {
    margin: 50px 50px 0;
    padding: 100px 0;
  }
  .c-hero01.-noPb::before {
    height: 380px;
  }
  .c-hero01 .c-hero01__text-en {
    font-size: 1rem;
  }
  .c-hero01 .c-hero01__text-ja {
    margin-top: 16px;
    font-size: 2.75rem;
  }
  .c-hero01 .c-hero01__contents {
    margin-top: 50px;
    padding: 32px 20px;
    flex-direction: row;
    gap: 10px;
  }
  .c-hero01 .c-hero01__contents-item {
    padding: 0 !important;
    flex-direction: column;
    width: calc(20% - 8px);
    border-bottom: none;
    gap: 10px;
  }
  .c-hero01 .c-hero01__contents-item-txt {
    width: 100%;
    text-align: center;
  }
  .c-hero01 .c-hero01__contents-item-img {
    width: 100px;
  }
}
@media all and ( 1320px < width ) {
  .c-hero01.-noPb {
    padding: 90px 0 0;
  }
  .c-hero01 .c-hero01__contents {
    padding: 32px 70px;
    gap: 40px;
  }
  .c-hero01 .c-hero01__contents-item {
    width: calc(20% - 32px);
  }
  .c-hero01 .c-hero01__contents-item-txt {
    font-size: 1.0625rem;
  }
}

/*  c-breadcrumb
----------------------------------- */
.c-breadcrumb {
  margin-top: 20px;
}
.c-breadcrumb .c-breadcrumb__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .c-breadcrumb .c-breadcrumb__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.c-breadcrumb .c-breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  font-size: 0.8125rem;
}
.c-breadcrumb .c-breadcrumb__list span {
  color: #2F1908;
}
.c-breadcrumb .c-breadcrumb__list a {
  display: inline-block;
  color: #2F1908;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
.c-breadcrumb .c-breadcrumb__list a:hover, .c-breadcrumb .c-breadcrumb__list a:focus-visible {
  opacity: 0.5;
}
.c-breadcrumb .c-breadcrumb__item {
  position: relative;
}
.c-breadcrumb .c-breadcrumb__item:nth-child(n+2)::before {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: -11px;
  translate: -50% -50%;
  width: 12px;
  height: 2px;
  background: #E3E2E0;
}
.c-breadcrumb .c-breadcrumb__item:nth-child(n+2) span {
  color: #938A83;
}

/*  c-table01
----------------------------------- */
.c-table01 table {
  table-layout: fixed;
}
.c-table01 th, .c-table01 td {
  display: block;
}
.c-table01 tr:last-child td {
  border-width: 0 1px 1px 1px;
}
.c-table01 th {
  padding: 16px 20px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  text-align: left;
  color: #fff;
  background: #EC6C00;
}
.c-table01 td {
  padding: 20px;
  border: solid #E3E2E0;
  border-width: 0 1px;
}
.c-table01 a {
  display: inline-block;
}
.c-table01 a:hover, .c-table01 a:focus-visible {
  text-decoration: none;
}
@media all and ( 1008px <= width ) {
  .c-table01 tr:first-child td {
    border-width: 1px 1px 1px 0;
  }
  .c-table01 tr:last-child th {
    border-bottom: none;
  }
  .c-table01 th, .c-table01 td {
    display: table-cell;
  }
  .c-table01 th {
    padding: 32px 50px;
    vertical-align: top;
    border-bottom: 1px solid #E3E2E0;
  }
  .c-table01 td {
    padding: 32px 50px 32px 40px;
    border-width: 0 1px 1px 0;
  }
}

/*  デフォルト
----------------------------------- */
input, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  border-radius: 0;
  border: 1px solid #E3E2E0;
  background: #fff;
}

input[type=text],
input[type=email],
textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
}

input::placeholder {
  color: #938A83;
  letter-spacing: 0.04em;
}

input:-ms-input-placeholder {
  color: #938A83;
  letter-spacing: 0.04em;
}

input::-ms-input-placeholder {
  color: #938A83;
  letter-spacing: 0.04em;
}

button {
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
}

label {
  cursor: pointer;
}

select {
  box-sizing: border-box;
  padding: 5px 30px 5px 10px;
  border-radius: 0;
  border: 1px solid #abacab;
  background: #fff;
}

input[type=radio] {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}
input[type=radio]:focus + span::before {
  background: #cbffb1;
}
input[type=radio] + span {
  position: relative;
  display: inline-block;
  padding-left: 28px;
}
input[type=radio] + span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 1px solid #2F1908;
  background: #fff;
  border-radius: 50%;
}

input[type=radio]:checked + span::before {
  border-color: #EC6C00;
}
input[type=radio]:checked + span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #EC6C00;
}

input[type=checkbox] {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}
input[type=checkbox]:focus + span::before {
  background: #F4EDE7;
}
input[type=checkbox] + span {
  position: relative;
  display: inline-block;
  padding-left: 30px;
}
input[type=checkbox] + span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #2F1908;
}

input[type=checkbox]:checked + span::before {
  border-color: #EC6C00;
}
input[type=checkbox]:checked + span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  width: 12px;
  height: 11px;
  background: url(../img/common/icon-check.svg) no-repeat center/contain;
}

/*  c-form01
----------------------------------- */
.c-form01 .c-form01__contents {
  margin: 0 -20px;
  padding: 50px 20px;
  background: #F5F5F5;
}
.c-form01 .c-form01__item:nth-child(n+2) {
  margin-top: 32px;
}
.c-form01 .c-form01__item.-privacy {
  display: grid;
  grid-template: "ttl link" auto "check check" auto;
}
.c-form01 .c-form01__item.-privacy .c-form01__ttl {
  grid-area: ttl;
}
.c-form01 .c-form01__item.-privacy .c-form01__policy {
  grid-area: link;
}
.c-form01 .c-form01__item.-privacy .c-form01__item-input {
  grid-area: check;
}
.c-form01 .c-form01__ttl {
  margin-bottom: 16px;
  display: inline-block;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
}
.c-form01 .c-form01__required {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 5px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 0.75rem;
  color: #fff;
  vertical-align: 2px;
  background: #EC6C00;
  border-radius: 2px;
}
.c-form01 .c-form01__add {
  display: grid;
  grid-template: "txt num" auto ". add" auto ". des" auto/auto 1fr;
  align-items: center;
  gap: 8px 14px;
}
.c-form01 .c-form01__add-txt {
  grid-area: txt;
}
.c-form01 input[name=postcode] {
  grid-area: num;
}
.c-form01 input[name=address] {
  grid-area: add;
}
.c-form01 input[name=add03] {
  grid-area: des;
}
.c-form01 .c-form01__radio {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.c-form01 .c-form01__radio span {
  margin-left: 0 !important;
}
.c-form01 .c-form01__policy {
  text-align: right;
}
.c-form01 .c-form01__policy-link {
  display: inline-block;
  font-size: 0.75rem;
  text-decoration: underline;
}
.c-form01 .c-form01__policy-link:hover, .c-form01 .c-form01__policy-link:focus-visible {
  text-decoration: none;
}
@media all and ( 768px <= width ) {
  .c-form01 .c-form01__contents {
    margin: 0;
    border-radius: 10px;
  }
}
@media all and ( 1008px <= width ) {
  .c-form01 .c-form01__contents {
    padding: 80px 120px;
  }
  .c-form01 .c-form01__required {
    margin-left: 12px;
    font-size: 0.8125rem;
  }
  .c-form01 .c-form01__ttl {
    margin-bottom: 20px;
  }
  .c-form01 .c-form01__item:nth-child(n+2) {
    margin-top: 37px;
  }
  .c-form01 .c-form01__add {
    grid-template: "txt num" ". add" ". des"/auto 1fr;
    align-items: center;
    column-gap: 20px;
  }
  .c-form01 .c-form01__radio {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px 32px;
  }
}

.c-form01__btn {
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.c-form01__btn button {
  position: relative;
  margin: 0 auto;
  padding: 10px 16px 10px 20px;
  width: 180px;
  font-family: "Murecho", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  line-height: 1.8;
  letter-spacing: 0.04em;
  font-weight: 400;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  text-align: left;
  background: #EC6C00;
  border-radius: 500px;
  border: 1px solid transparent;
  transition: 0.25s cubic-bezier(0.5, 0.26, 0.23, 0.93);
  transition-property: all;
}
@media all and ( 768px <= width ) {
  .c-form01__btn button {
    font-size: 1.0625rem;
  }
}
.c-form01__btn button::after {
  content: "";
  position: absolute;
  display: block;
  width: 16px;
  height: 12px;
  right: 10px;
  top: 40%;
  background: #fff;
  mask: url(../img/common/icon-arw.svg) no-repeat center/contain;
}
.c-form01__btn button:hover, .c-form01__btn button:focus-visible {
  color: #EC6C00;
  background: #fff;
  border: 1px solid #EC6C00;
}
.c-form01__btn button:hover::after, .c-form01__btn button:focus-visible::after {
  background: #EC6C00;
}
.c-form01__btn button[value=back] {
  color: #EC6C00;
  border: 1px solid #EC6C00;
  background: #fff;
}
.c-form01__btn button[value=back]::after {
  background: #EC6C00;
}
.c-form01__btn button[value=back]:hover, .c-form01__btn button[value=back]:focus-visible {
  color: #fff;
  background: #EC6C00;
}
.c-form01__btn button[value=back]:hover::after, .c-form01__btn button[value=back]:focus-visible::after {
  background: #fff;
}
@media all and ( 1008px <= width ) {
  .c-form01__btn {
    margin-top: 40px;
    flex-direction: row;
  }
  .c-form01__btn button {
    padding: 16px 32px;
    width: 250px;
  }
  .c-form01__btn button::after {
    right: 32px;
  }
  .c-form01__btn button[value=back] {
    margin-right: 0;
  }
  .c-form01__btn button[value=send] {
    margin-left: 0;
  }
}

.mw_wp_form.mw_wp_form_confirm,
.mw_wp_form.mw_wp_form_complete {
  margin-top: -60px;
  padding-top: 60px;
}
@media all and ( 1008px <= width ) {
  .mw_wp_form.mw_wp_form_confirm,
.mw_wp_form.mw_wp_form_complete {
    margin-top: -150px;
    padding-top: 150px;
  }
}

.p-country-name {
  display: none;
}

/*  c-article01
----------------------------------- */
.c-article01 {
  margin: 0 -20px;
  padding: 40px 20px 60px;
  background: #F4EDE7;
}
.c-article01 .c-article01__thumb {
  margin-bottom: 40px;
}
.c-article01 .c-article01__thumb img {
  border-radius: 10px;
}
.c-article01__contents {
  overflow: hidden;
}
.c-article01__contents > * {
  margin-top: 32px;
}
.c-article01__contents > *:first-child {
  margin-top: 0;
}
.c-article01__contents h2, .c-article01__contents h3 {
  clear: both;
}
.c-article01__contents h2 {
  position: relative;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.375rem;
  border-bottom: 1px solid #DBCDC2;
}
.c-article01__contents h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 5px;
  height: calc(100% - 26px);
  top: 6px;
  left: 0;
  background: #EC6C00;
}
.c-article01__contents h3 {
  margin-bottom: 16px;
  padding-bottom: 20px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.25rem;
  border-bottom: 1px solid #DBCDC2;
}
.c-article01__contents h4 {
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.125rem;
}
.c-article01__contents strong, .c-article01__contents b {
  position: relative;
  display: inline;
  color: #EC6C00;
}
.c-article01__contents ul, .c-article01__contents ol {
  list-style: none;
}
.c-article01__contents ul li, .c-article01__contents ol li {
  position: relative;
}
.c-article01__contents ul li:nth-child(n+2), .c-article01__contents ol li:nth-child(n+2) {
  margin-top: 6px;
}
.c-article01__contents ul li {
  padding-left: 16px;
}
.c-article01__contents ul li::before {
  content: "";
  position: absolute;
  display: block;
  width: 6px;
  height: 2px;
  top: 15px;
  left: 0;
  background: #2F1908;
}
.c-article01__contents ol {
  counter-reset: item;
}
.c-article01__contents ol li {
  text-indent: -20px;
  padding-left: 20px;
}
.c-article01__contents ol li::before {
  counter-increment: item;
  content: counter(item) ". ";
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
}
.c-article01__contents img {
  width: auto;
  max-width: 100%;
}
@media all and ( 768px <= width ) {
  .c-article01 {
    margin: 0;
  }
}
@media all and ( 1008px <= width ) {
  .c-article01 {
    padding: 100px 110px;
  }
  .c-article01 .c-article01__thumb {
    margin-bottom: 80px;
  }
  .c-article01__contents {
    margin: 0 auto;
    width: min(920px, 100%);
  }
  .c-article01__contents > * {
    margin-top: 40px;
  }
  .c-article01__contents > *:first-child {
    margin-top: 0;
  }
  .c-article01__contents h2 {
    margin-bottom: 32px;
    padding-left: 30px;
    font-size: 1.75rem;
  }
  .c-article01__contents h2::before {
    width: 6px;
  }
  .c-article01__contents h3 {
    margin-bottom: 24px;
    font-size: 1.5rem;
  }
  .c-article01__contents h4 {
    font-size: 1.25rem;
  }
  .c-article01__contents ul li:nth-child(n+2), .c-article01__contents ol li:nth-child(n+2) {
    margin-top: 8px;
  }
  .c-article01__contents ul li::before {
    width: 8px;
    height: 3px;
    top: 14px;
  }
  .c-article01__contents ol li {
    padding-left: 22px;
  }
  .c-article01__contents .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .c-article01__contents .aligncenter img {
    display: block;
    margin: 0 auto;
  }
  .c-article01__contents .alighleft {
    float: left;
    padding: 0 40px 40px 0;
  }
  .c-article01__contents .alignright {
    float: right;
    padding: 0 0 40px 40px;
  }
}

.u-clearfix {
  position: relative;
}
.u-clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.u-txt-uppercase {
  text-transform: uppercase;
}

.u-txt-center {
  text-align: center;
}

.u-txt-left {
  text-align: left;
}

.u-txt-right {
  text-align: right;
}

@media all and ( 768px <= width ) {
  .u-view-small {
    display: none !important;
  }
}

@media all and ( width < 768px) {
  .u-view-medium {
    display: none !important;
  }
}
@media all and ( 1008px <= width ) {
  .u-view-medium {
    display: none !important;
  }
}

@media all and (width < 1008px) {
  .u-view-large {
    display: none !important;
  }
}
@media all and (1320px <= width) {
  .u-view-large {
    display: none !important;
  }
}

@media all and (width < 1320px) {
  .u-view-full {
    display: none !important;
  }
}

@media all and ( 1008px <= width ) {
  .u-view-under-medium {
    display: none !important;
  }
}

@media all and (width < 1008px) {
  .u-view-upper-large {
    display: none !important;
  }
}

/*  404
================================================ */
.p-page404 {
  padding: 50px 0;
}
.p-page404 .p-page404__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-page404 .p-page404__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media all and ( 768px <= width ) {
  .p-page404 {
    padding: 100px 0;
  }
}

/*  p-index-hero
----------------------------------- */
.p-index-hero .p-index-hero__contentsWrap {
  background: #F4EDE7;
}
.p-index-hero .p-index-hero__contents {
  padding: 50px 20px 0;
}
.p-index-hero .p-index-hero__contents-ttl {
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  text-align: center;
}
.p-index-hero .p-index-hero__contents-ttl-small {
  display: block;
  font-size: 0.9375rem;
}
.p-index-hero .p-index-hero__contents-ttl-main {
  font-size: 2.25rem;
}
.p-index-hero .p-index-hero__contents-btn {
  margin-top: 24px;
}
.p-index-hero .p-index-hero__point {
  display: flex;
  margin-top: 32px;
  gap: 7px;
}
.p-index-hero .p-index-hero__point-item {
  width: calc(33.33% - 4.6666666667px);
}
@media all and ( 768px <= width ) {
  .p-index-hero .p-index-hero__point {
    justify-content: center;
    gap: 12px;
  }
  .p-index-hero .p-index-hero__point-item {
    width: min(200px, 100%);
  }
}
@media all and ( 1008px <= width ) {
  .p-index-hero .p-index-hero__contentsWrap {
    position: relative;
    margin: 0 50px;
  }
  .p-index-hero .p-index-hero__contents-img {
    position: absolute;
    bottom: 0;
    right: -100px;
  }
  .p-index-hero .p-index-hero__contents {
    position: relative;
    padding: 100px 0 100px 80px;
    z-index: 1;
  }
  .p-index-hero .p-index-hero__contents-ttl-small {
    text-align: left;
    font-size: 1.75rem;
  }
  .p-index-hero .p-index-hero__contents-ttl-main {
    font-size: 3.5rem;
    text-align: left;
  }
  .p-index-hero .p-index-hero__contents-btn {
    margin-top: 40px;
  }
  .p-index-hero .p-index-hero__point {
    margin-top: 64px;
    justify-content: start;
    gap: 20px;
  }
  .p-index-hero .p-index-hero__point-item {
    width: 140px;
  }
}
@media all and ( 1320px < width ) {
  .p-index-hero .p-index-hero__contentsWrap {
    margin: 70px 100px 0;
  }
  .p-index-hero .p-index-hero__contents {
    padding: 124px 0 124px 160px;
  }
}

/*  p-index-message
----------------------------------- */
.p-index-message {
  position: relative;
  padding: 80px 0 70px;
}
.p-index-message::before {
  content: "";
  position: absolute;
  display: block;
  width: 100vw;
  background: url(../img/common/img-deco.svg) no-repeat top center/contain;
  bottom: 1px;
  translate: 0 100%;
  aspect-ratio: 1920/120;
  z-index: 1;
}
.p-index-message .p-index-message__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-index-message .p-index-message__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-message .p-index-message__contents {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.p-index-message .p-index-message__contents-img {
  margin: 0 auto;
  width: 255px;
}
.p-index-message .p-index-message__contents-txtArea {
  padding: 0 20px;
}
.p-index-message .p-index-message__contents-ttl {
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.625rem;
}
.p-index-message .p-index-message__contents-ttl-small {
  display: block;
  padding-bottom: 8px;
  font-size: 1.0625rem;
}
.p-index-message .p-index-message__contents-txt {
  margin-top: 24px;
}
@media all and ( 1008px <= width ) {
  .p-index-message {
    padding: 120px 0 70px;
  }
  .p-index-message .p-index-message__contents {
    flex-direction: row;
    align-items: center;
    gap: 90px;
  }
  .p-index-message .p-index-message__contents-img {
    margin: 0;
    width: 330px;
  }
  .p-index-message .p-index-message__contents-txtArea {
    padding: 0;
    width: calc(100% - 420px);
  }
  .p-index-message .p-index-message__contents-ttl {
    font-size: 2.5rem;
  }
  .p-index-message .p-index-message__contents-ttl-small {
    padding-bottom: 12px;
    font-size: 1.375rem;
  }
  .p-index-message .p-index-message__contents-txt {
    margin-top: 40px;
  }
}
@media all and ( 1320px < width ) {
  .p-index-message .p-index-message__contents {
    gap: 150px;
  }
  .p-index-message .p-index-message__contents-img {
    width: 510px;
  }
  .p-index-message .p-index-message__contents-txtArea {
    width: calc(100% - 660px);
  }
  .p-index-message .p-index-message__contents-txt {
    margin-top: 50px;
  }
}

/*  p-index-service
----------------------------------- */
.p-index-service {
  position: relative;
  padding: 102px 0 72px;
  background: #F4EDE7;
}
.p-index-service::before {
  content: "";
  position: absolute;
  display: block;
  width: 100vw;
  mask: url(../img/common/img-deco.svg) no-repeat top center/contain;
  background: #F4EDE7;
  bottom: 1px;
  translate: 0 100%;
  aspect-ratio: 1920/120;
}
.p-index-service .p-index-service__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-index-service .p-index-service__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-service .p-index-service__contents {
  margin-top: 32px;
  padding: 8px;
  background: #fff;
  border-radius: 10px;
}
.p-index-service .p-index-service__contents-ttl {
  padding: 12px 24px;
  background: #F5E4C4;
  border-radius: 10px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  text-align: center;
}
.p-index-service .p-index-service__contents-ttl strong {
  color: #EC6C00;
}
.p-index-service .p-index-service__contents-img {
  padding: 16px;
}
@media all and ( 1008px <= width ) {
  .p-index-service {
    padding: 240px 0 70px;
  }
  .p-index-service .p-index-service__contents {
    margin-top: 35px;
    padding: 16px 16px 0;
  }
  .p-index-service .p-index-service__contents-ttl {
    padding: 16px;
    font-size: 1.25rem;
  }
  .p-index-service .p-index-service__contents-img {
    padding: 38px 64px;
  }
}

/*  p-index-feature
----------------------------------- */
.p-index-feature {
  padding: 102px 0 60px;
}
.p-index-feature .p-index-feature__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-index-feature .p-index-feature__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-feature .p-index-feature__contents {
  border: solid #E3E2E0;
  border-width: 1px 0;
}
.p-index-feature .p-index-feature__contents-item {
  display: flex;
  flex-direction: column;
  padding: 32px 10px;
  border-bottom: 1px solid #E3E2E0;
  gap: 20px;
}
.p-index-feature .p-index-feature__contents-item:last-child {
  border-bottom: none;
}
.p-index-feature .p-index-feature__contents-item.-orange strong {
  color: #EC6C00;
}
.p-index-feature .p-index-feature__contents-item.-green strong {
  color: #3D904A;
}
.p-index-feature .p-index-feature__contents-item.-blue strong {
  color: #4B74C3;
}
.p-index-feature .p-index-feature__contents-img img {
  border-radius: 10px;
}
.p-index-feature .p-index-feature__contents-num {
  font-size: 0.75rem;
  color: #938A83;
}
.p-index-feature .p-index-feature__contents-ttl {
  margin-top: 8px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.375rem;
}
.p-index-feature .p-index-feature__contents-txt {
  margin-top: 20px;
}
@media all and ( 1008px <= width ) {
  .p-index-feature {
    padding: 240px 0 100px;
  }
  .p-index-feature .p-index-feature__contents-item {
    flex-direction: row;
    padding: 60px 20px;
    align-items: center;
    gap: 50px;
  }
  .p-index-feature .p-index-feature__contents-img {
    width: 350px;
  }
  .p-index-feature .p-index-feature__contents-txtArea {
    width: calc(100% - 400px);
  }
  .p-index-feature .p-index-feature__contents-num {
    font-size: 0.9375rem;
  }
  .p-index-feature .p-index-feature__contents-ttl {
    margin-top: 10px;
    font-size: 1.625rem;
  }
  .p-index-feature .p-index-feature__contents-txt {
    margin-top: 40px;
  }
}
@media all and ( 1320px < width ) {
  .p-index-feature .p-index-feature__contents-item {
    gap: 110px;
  }
  .p-index-feature .p-index-feature__contents-img {
    width: 530px;
  }
  .p-index-feature .p-index-feature__contents-txtArea {
    width: calc(100% - 640px);
  }
  .p-index-feature .p-index-feature__contents-ttl {
    font-size: 2rem;
  }
  .p-index-feature .p-index-feature__contents-txt {
    margin-top: 60px;
  }
}

/*  p-index-setting
----------------------------------- */
.p-index-setting .p-index-setting__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-index-setting .p-index-setting__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-setting .p-index-setting__bg {
  padding: 50px 24px;
  background: #F5F5F5;
}
.p-index-setting .p-index-setting__note {
  margin-top: 10px;
}
.p-index-setting .p-index-setting__note-item {
  position: relative;
  display: inline;
  padding-right: 20px;
  text-decoration: underline #938A83;
  cursor: pointer;
}
.p-index-setting .p-index-setting__note-item::after {
  content: "";
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  top: 3px;
  right: 0;
  background: url(../img/common/icon-modal01.svg) no-repeat center/contain;
}
.p-index-setting .p-index-setting__note-item:hover, .p-index-setting .p-index-setting__note-item:focus-visible {
  text-decoration: none;
}
.p-index-setting .p-index-setting__contents {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  gap: 8px;
}
.p-index-setting .p-index-setting__contents-item {
  padding: 8px 8px 0 8px;
  background: #fff;
  border: 1px solid #E3E2E0;
  border-radius: 10px;
}
.p-index-setting .p-index-setting__contents-img {
  padding: 16px 20px;
  background: #E4EFE6;
  border-radius: 10px;
}
.p-index-setting .p-index-setting__contents-txt {
  padding: 18px 24px;
}
.p-index-setting .p-index-setting__contents-txt strong {
  font-weight: 500;
}
@media all and ( 1008px <= width ) {
  .p-index-setting .p-index-setting__bg {
    margin: 0 -220px;
    padding: 100px 220px;
  }
  .p-index-setting .p-index-setting__note {
    margin-top: 10px;
  }
  .p-index-setting .p-index-setting__note-item::after {
    top: 5px;
  }
  .p-index-setting .p-index-setting__contents {
    margin-top: 40px;
    flex-direction: row;
    gap: 20px;
  }
  .p-index-setting .p-index-setting__contents-item {
    padding: 12px 12px 0 12px;
    width: calc(33.33% - 13.3333333333px);
  }
  .p-index-setting .p-index-setting__contents-img {
    padding: 22px 28px;
  }
  .p-index-setting .p-index-setting__contents-txt {
    padding: 26px;
    text-align: center;
  }
}
@media all and ( 1320px < width ) {
  .p-index-setting .p-index-setting__contents {
    gap: 40px;
  }
  .p-index-setting .p-index-setting__contents-item {
    padding: 16px 16px 0 16px;
    width: calc(33.33% - 26.6666666667px);
  }
  .p-index-setting .p-index-setting__contents-img {
    padding: 12px 28px;
  }
}

/*  p-index-flow
----------------------------------- */
.p-index-flow {
  position: relative;
  padding: 80px 0;
}
.p-index-flow::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: -80px;
  background: url(../img/home/img-flow_S.png) no-repeat top/cover;
  z-index: -1;
}
.p-index-flow .p-index-flow__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-index-flow .p-index-flow__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-flow .p-index-flow__step {
  margin-top: 40px;
}
.p-index-flow .p-index-flow__step-child-btnWrap {
  margin-top: 16px;
}
.p-index-flow .p-index-flow__step-child-btn {
  margin: 0 auto;
  width: 256px;
}
.p-index-flow .p-index-flow__step-child-btn:nth-child(n+2) {
  margin-top: 8px;
}
.p-index-flow .p-index-flow__step-child-ttl {
  margin-top: 14px;
  padding-bottom: 10px;
  font-weight: 500;
  border-bottom: 1px solid #DBCDC2;
}
.p-index-flow .p-index-flow__step-child-list {
  margin-top: 10px;
}
.p-index-flow .p-index-flow__step-child-note {
  margin-top: 4px;
}
@media all and ( 1008px <= width ) {
  .p-index-flow {
    padding: 130px 0;
  }
  .p-index-flow::before {
    top: 40px;
    left: -150px;
    background: url(../img/home/img-flow_L.png) no-repeat top right/cover;
  }
  .p-index-flow .p-index-flow__head {
    margin-bottom: 35px;
  }
  .p-index-flow .p-index-flow__txt {
    text-align: center;
  }
  .p-index-flow .p-index-flow__step {
    margin-top: 80px;
  }
  .p-index-flow .p-index-flow__step-child-ttl {
    padding-bottom: 8px;
  }
  .p-index-flow .p-index-flow__step-child-list {
    margin-top: 12px;
  }
  .p-index-flow .p-index-flow__step-child-note {
    margin-top: 8px;
  }
}

/*  p-index-contact
----------------------------------- */
.p-index-contact .p-index-contact__ttl-ja {
  font-size: 1.75rem;
}
.p-index-contact .p-index-contact__ttl-ja-small {
  display: block;
  font-size: 1.125rem;
}
@media all and ( 1008px <= width ) {
  .p-index-contact .p-index-contact__ttl-ja-small {
    display: block;
    font-size: 2rem;
  }
}
@media all and ( 1320px < width ) {
  .p-index-contact .p-index-contact__ttl-ja-small {
    font-size: 3rem;
  }
}

/*  p-index-info
----------------------------------- */
.p-index-info {
  padding: 80px 0 100px;
}
.p-index-info .p-index-info__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-index-info .p-index-info__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media all and ( 1008px <= width ) {
  .p-index-info {
    padding: 130px 0;
  }
}

/*  p-index-faq
----------------------------------- */
.p-index-faq {
  position: relative;
}
.p-index-faq .p-index-faq__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-index-faq .p-index-faq__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-faq .p-index-faq__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #F4EDE7;
  z-index: -1;
}
.p-index-faq .p-index-faq__container {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
}
.p-index-faq .p-index-faq__container-head {
  margin-bottom: 40px;
}
.p-index-faq .p-index-faq__container-head-icon {
  margin: 0 auto;
  width: 27px;
}
.p-index-faq .p-index-faq__container-head-ttl {
  padding-top: 14px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.625rem;
  text-align: center;
}
@media all and ( 1008px <= width ) {
  .p-index-faq .p-index-faq__bg::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    aspect-ratio: 1280/340;
    background: url(../img/home/img-faq-bg.svg) no-repeat bottom/contain;
    z-index: -1;
  }
  .p-index-faq .p-index-faq__container-head {
    margin-bottom: 80px;
  }
  .p-index-faq .p-index-faq__container-head-icon {
    width: 36px;
  }
  .p-index-faq .p-index-faq__container-head-ttl {
    padding-top: 24px;
    font-size: 2.5rem;
  }
}
@media all and ( 1320px < width ) {
  .p-index-faq .p-index-faq__bg {
    margin-left: auto;
    right: 0;
  }
  .p-index-faq .p-index-faq__bg::before {
    width: 70%;
    bottom: 50px;
  }
  .p-index-faq .p-index-faq__container {
    padding: 120px 0 100px;
    flex-direction: row;
    gap: 156px;
  }
  .p-index-faq .p-index-faq__container-head {
    display: flex;
    width: 357px;
    align-self: start;
    align-items: center;
    gap: 24px;
  }
  .p-index-faq .p-index-faq__container-head-ttl {
    padding-top: 0;
  }
  .p-index-faq .p-index-faq__contents {
    width: calc(100% - 513px);
  }
}
@media all and (width >= 1600px) {
  .p-index-faq .p-index-faq__bg {
    width: calc(100% - 160px);
  }
}

/*  p-service
----------------------------------- */
.p-service {
  padding-top: 80px;
}
@media all and ( 1008px <= width ) {
  .p-service {
    padding-top: 90px;
  }
}

/*  p-service__intro
----------------------------------- */
.p-service__intro {
  padding-bottom: 80px;
}
.p-service__intro .p-service__intro-inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-service__intro .p-service__intro-inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-service__intro .p-service__intro-contents {
  margin-top: 32px;
  padding: 8px;
  background: #fff;
  border: 1px solid #DBCDC2;
  border-radius: 10px;
}
.p-service__intro .p-service__intro-contents-ttl {
  padding: 12px 24px;
  background: #F5E4C4;
  border-radius: 10px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  text-align: center;
}
.p-service__intro .p-service__intro-contents-ttl strong {
  color: #EC6C00;
}
.p-service__intro .p-service__intro-contents-img {
  padding: 16px;
}
@media all and ( 768px <= width ) {
  .p-service__intro .p-service__intro-contents-img {
    margin: 0 auto;
    width: 60%;
  }
}
@media all and ( 1008px <= width ) {
  .p-service__intro {
    padding-bottom: 130px;
  }
  .p-service__intro .p-service__intro-contents {
    margin-top: 40px;
    padding: 16px 16px 0;
  }
  .p-service__intro .p-service__intro-contents-ttl {
    padding: 16px;
    font-size: 1.25rem;
  }
  .p-service__intro .p-service__intro-contents-img {
    margin: 0;
    padding: 38px 64px;
    width: 100%;
  }
}

/*  p-service-factoring
----------------------------------- */
.p-service-factoring {
  padding: 80px 0;
  background: #F4EDE7;
}
.p-service-factoring .p-service-factoring__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-service-factoring .p-service-factoring__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-service-factoring .p-service-factoring__contents {
  display: flex;
  flex-direction: column;
}
.p-service-factoring .p-service-factoring__contents-img img {
  border-radius: 10px;
}
.p-service-factoring .p-service-factoring__contents-txtArea {
  margin-top: 24px;
}
.p-service-factoring .p-service-factoring__contents-ttl {
  font-size: 1.375rem;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
}
.p-service-factoring .p-service-factoring__contents-txt {
  margin-top: 20px;
}
.p-service-factoring .p-service-factoring__merit {
  margin-top: 40px;
}
.p-service-factoring .p-service-factoring__merit-item {
  margin-top: 32px;
  padding: 16px;
  background: #fff;
  border-radius: 10px;
}
.p-service-factoring .p-service-factoring__merit-item-bg {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #F5F5F5;
  border-radius: 10px;
}
.p-service-factoring .p-service-factoring__merit-item-child {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E3E2E0;
}
.p-service-factoring .p-service-factoring__merit-item-child:first-child {
  padding-top: 0;
}
.p-service-factoring .p-service-factoring__merit-item-child:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.p-service-factoring .p-service-factoring__merit-child-num {
  margin-bottom: 5px;
  font-size: 0.75rem;
  color: #EC6C00;
  font-weight: 500;
}
.p-service-factoring .p-service-factoring__merit-child-txt {
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
}
@media all and ( 1008px <= width ) {
  .p-service-factoring {
    padding: 130px 0;
  }
  .p-service-factoring .p-service-factoring__contents {
    flex-direction: row-reverse;
    align-items: center;
  }
  .p-service-factoring .p-service-factoring__contents-img {
    width: 360px;
  }
  .p-service-factoring .p-service-factoring__contents-txtArea {
    margin-top: 0;
    margin-right: 50px;
    width: calc(100% - 410px);
  }
  .p-service-factoring .p-service-factoring__contents-ttl {
    font-size: 1.75rem;
  }
  .p-service-factoring .p-service-factoring__contents-txt {
    margin-top: 40px;
  }
  .p-service-factoring .p-service-factoring__merit {
    margin-top: 80px;
  }
  .p-service-factoring .p-service-factoring__merit-item {
    margin-top: 30px;
  }
  .p-service-factoring .p-service-factoring__merit-item-bg {
    padding: 32px 70px;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;
  }
  .p-service-factoring .p-service-factoring__merit-item-child {
    flex-direction: row;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    width: calc(50% - 10px);
    gap: 24px;
  }
  .p-service-factoring .p-service-factoring__merit-item-child:nth-child(n+1) {
    padding-top: 0;
  }
  .p-service-factoring .p-service-factoring__merit-item-child:nth-child(n+3) {
    padding-top: 24px;
    padding-bottom: 0;
    border-bottom: none;
  }
  .p-service-factoring .p-service-factoring__merit-item-child:nth-child(n+5) {
    margin-top: 24px;
    border-top: 1px solid #E3E2E0;
  }
  .p-service-factoring .p-service-factoring__merit-child-num {
    width: 50px;
    margin-bottom: 0;
  }
  .p-service-factoring .p-service-factoring__merit-child-txt {
    width: calc(100% - 50px);
    font-size: 1.0625rem;
    line-height: 1.6;
    letter-spacing: 0.06em;
    font-weight: 700;
  }
}
@media all and ( 1320px < width ) {
  .p-service-factoring .p-service-factoring__contents-img {
    width: 660px;
  }
  .p-service-factoring .p-service-factoring__contents-txtArea {
    margin-right: 110px;
    width: calc(100% - 770px);
  }
  .p-service-factoring .p-service-factoring__contents-ttl {
    font-size: 2rem;
  }
  .p-service-factoring .p-service-factoring__merit-item-bg {
    padding: 32px 70px;
    column-gap: 40px;
  }
  .p-service-factoring .p-service-factoring__merit-item-child {
    width: calc(50% - 20px);
  }
}

/*  p-service-feature
----------------------------------- */
.p-service-feature {
  padding: 80px 0;
}
.p-service-feature .p-service-feature__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-service-feature .p-service-feature__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-service-feature .p-service-feature__contents-itemWrap {
  padding: 30px 0;
}
.p-service-feature .p-service-feature__contents-itemWrap:first-child {
  padding: 0 0 30px 0;
}
.p-service-feature .p-service-feature__contents-itemWrap:last-child {
  padding: 30px 0 0 0;
}
.p-service-feature .p-service-feature__contents-item {
  display: flex;
  flex-direction: column;
}
.p-service-feature .p-service-feature__contents-item.-orange strong {
  color: #EC6C00;
}
.p-service-feature .p-service-feature__contents-item.-green strong {
  color: #3D904A;
}
.p-service-feature .p-service-feature__contents-item.-blue strong {
  color: #4B74C3;
}
.p-service-feature .p-service-feature__contents-img img {
  border-radius: 10px;
}
.p-service-feature .p-service-feature__contents-txtArea {
  margin-top: 20px;
}
.p-service-feature .p-service-feature__contents-num {
  color: #938A83;
  font-size: 0.75rem;
}
.p-service-feature .p-service-feature__contents-ttl {
  margin-top: 8px;
  font-size: 1.375rem;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
}
.p-service-feature .p-service-feature__contents-txt {
  margin-top: 20px;
}
.p-service-feature .p-service-feature__contact {
  margin-top: 32px;
  padding: 24px 16px;
  background: #F4EDE7;
  border-radius: 10px;
}
.p-service-feature .p-service-feature__contact-ttlArea {
  margin-bottom: 24px;
  padding: 0 16px;
}
.p-service-feature .p-service-feature__contact-ttl {
  font-size: 1.5rem;
  font-weight: 500;
  color: #EC6C00;
  text-align: center;
}
.p-service-feature .p-service-feature__contact-txt {
  margin-top: 14px;
}
.p-service-feature .p-index-flow__step {
  margin-top: 50px;
  gap: 36px;
}
.p-service-feature .p-index-flow__step-item {
  position: relative;
}
.p-service-feature .p-index-flow__step-item::after {
  content: "";
  position: absolute;
  display: block;
  width: 32px;
  height: 16px;
  bottom: -26px;
  right: 50%;
  translate: 50% 0%;
  background: url(../img/service/icon-step-arw.svg) no-repeat center/contain;
}
.p-service-feature .p-index-flow__step-item:last-child::after {
  display: none;
}
.p-service-feature .p-index-flow__step-child-img {
  margin: 16px auto 0;
  width: 200px;
}
.p-service-feature .p-service-feature__contents-item-child {
  margin-top: 40px;
}
@media all and ( 1008px <= width ) {
  .p-service-feature {
    padding: 130px 0;
  }
  .p-service-feature .p-service-feature__contents-itemWrap {
    padding: 50px 0;
  }
  .p-service-feature .p-service-feature__contents-itemWrap:first-child {
    padding: 0 0 50px 0;
  }
  .p-service-feature .p-service-feature__contents-itemWrap:last-child {
    padding: 50px 0 0 0;
  }
  .p-service-feature .p-service-feature__contents-item {
    margin-left: -50px;
    flex-direction: row;
    align-items: center;
    gap: 70px;
  }
  .p-service-feature .p-service-feature__contents-item.-rev {
    margin-left: 0;
    margin-right: -50px;
    flex-direction: row-reverse;
  }
  .p-service-feature .p-service-feature__contents-img {
    width: 350px;
  }
  .p-service-feature .p-service-feature__contents-txtArea {
    margin-top: 0;
    width: calc(100% - 420px);
  }
  .p-service-feature .p-service-feature__contents-num {
    font-size: 0.9375rem;
  }
  .p-service-feature .p-service-feature__contents-ttl {
    margin-top: 10px;
    font-size: 1.75rem;
  }
  .p-service-feature .p-service-feature__contents-txt {
    margin-top: 28px;
  }
  .p-service-feature .p-service-feature__contact {
    margin-top: 80px;
    padding: 40px 20px;
  }
  .p-service-feature .p-service-feature__contact-contents {
    grid-template-columns: 1fr;
  }
  .p-service-feature .p-service-feature__contact-item {
    margin-top: 0;
    padding: 30px 20px !important;
  }
  .p-service-feature .p-service-feature__contact-ttlArea {
    margin-bottom: 40px;
    padding: 0;
  }
  .p-service-feature .p-service-feature__contact-ttl {
    font-size: 2.5rem;
  }
  .p-service-feature .p-service-feature__contact-txt {
    margin-top: 16px;
    text-align: center;
  }
  .p-service-feature .p-index-flow__step {
    margin-top: 40px;
    gap: 40px;
  }
  .p-service-feature .p-index-flow__step-item::after {
    width: 20px;
    height: 17px;
    bottom: 50%;
    right: -20px;
    rotate: -90deg;
  }
  .p-service-feature .p-index-flow__step-child-img {
    margin: 24px auto 0;
    width: 253px;
  }
  .p-service-feature .p-service-feature__contents-item-child {
    margin-top: 80px;
  }
}
@media all and (width >= 1200px) {
  .p-service-feature .p-service-feature__contact-contents {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media all and ( 1320px < width ) {
  .p-service-feature .p-service-feature__contents-item {
    margin-left: -160px;
    gap: 110px;
  }
  .p-service-feature .p-service-feature__contents-item.-rev {
    margin-right: -160px;
  }
  .p-service-feature .p-service-feature__contents-img {
    width: min(770px, 100%);
  }
  .p-service-feature .p-service-feature__contents-txtArea {
    width: calc(100% - 880px);
  }
  .p-service-feature .p-service-feature__contents-ttl {
    font-size: 2rem;
  }
  .p-service-feature .p-service-feature__contents-txt {
    margin-top: 48px;
  }
  .p-service-feature .p-service-feature__contact {
    padding: 60px 80px;
  }
  .p-service-feature .p-service-feature__contact-item {
    padding: 45px 32px !important;
  }
}

/*  p-service-faq
----------------------------------- */
.p-service-faq {
  padding: 80px 0;
  background: #F4EDE7;
}
.p-service-faq .p-service-faq__inner {
  width: min(100%, 960px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-service-faq .p-service-faq__inner {
    width: min(100%, 1120px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-service-faq .p-service-faq__head {
  margin-bottom: 24px;
}
.p-service-faq .p-service-faq__txt {
  text-align: center;
}
.p-service-faq .p-service-faq__contents {
  margin-top: 40px;
}
@media all and ( 1008px <= width ) {
  .p-service-faq {
    padding: 130px 0;
  }
  .p-service-faq .p-service-faq__head {
    margin-bottom: 40px;
  }
  .p-service-faq .p-service-faq__contents {
    margin-top: 80px;
  }
}

/*  p-message
----------------------------------- */
.p-message {
  padding-top: 80px;
}
@media all and ( 1008px <= width ) {
  .p-message {
    padding-top: 90px;
  }
}

/*  p-message__intro
----------------------------------- */
.p-message__intro {
  position: relative;
  padding-bottom: 80px;
}
.p-message__intro::before {
  content: "";
  position: absolute;
  display: block;
  width: 100vw;
  bottom: 1px;
  translate: 0 100%;
  background: url(../img/common/img-deco.svg) no-repeat top center/contain;
  z-index: 1;
  aspect-ratio: 1920/120;
}
.p-message__intro .p-message__intro-inner {
  width: min(100%, 790px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-message__intro .p-message__intro-inner {
    width: min(100%, 950px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-message__intro .p-message__intro-logo {
  margin: 0 auto;
  width: 143px;
}
.p-message__intro .p-message__intro-txtWrap {
  margin-top: 31px;
  padding-left: 20px;
  padding-right: 20px;
}
.p-message__intro .p-message__intro-img {
  margin: 40px auto 0;
  width: 255px;
}
@media all and ( 1008px <= width ) {
  .p-message__intro {
    padding-bottom: 70px;
  }
  .p-message__intro .p-message__intro-logo {
    width: 200px;
  }
  .p-message__intro .p-message__intro-txtWrap {
    margin-top: 70px;
    padding-left: 0;
    padding-right: 0;
  }
  .p-message__intro .p-message__intro-txt {
    line-height: 2;
    text-align: center;
    font-size: 1.1875rem;
  }
  .p-message__intro .p-message__intro-txt:nth-child(n+2) {
    margin-top: 30px;
  }
  .p-message__intro .p-message__intro-img {
    margin: 45px auto 0;
    width: 160px;
  }
}

/*  p-message__contents
----------------------------------- */
.p-message__contents {
  position: relative;
  padding: 102px 0 16px;
  background: #F4EDE7;
}
.p-message__contents::before {
  content: "";
  display: block;
  position: absolute;
  width: 100vw;
  bottom: 1px;
  translate: 0 100%;
  mask: url(../img/common/img-deco.svg) no-repeat top center/contain;
  background: #F4EDE7;
  aspect-ratio: 1920/120;
}
.p-message__contents .p-message__contents-inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-message__contents .p-message__contents-inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-message__contents .p-message__contents-ttl {
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.375rem;
}
.p-message__contents .p-message__contents-ttl strong {
  color: #EC6C00;
}
.p-message__contents .p-message__contents-txtWrap {
  margin-top: 24px;
}
.p-message__contents .p-message__contents-txt:nth-child(n+2) {
  margin-top: 16px;
}
.p-message__contents .p-message__contents-name {
  margin-top: 20px;
}
.p-message__contents .p-message__contents-name-small {
  padding-right: 10px;
  font-size: 0.8125rem;
}
.p-message__contents .p-message__contents-img {
  margin-top: 20px;
}
@media all and ( 1008px <= width ) {
  .p-message__contents {
    padding: 240px 0 270px;
  }
  .p-message__contents .p-message__contents-container {
    position: relative;
    width: 660px;
    margin-left: 0;
    z-index: 1;
  }
  .p-message__contents .p-message__contents-img {
    position: absolute;
    margin-top: 0;
    bottom: -10px;
    right: 0;
  }
  .p-message__contents .p-message__contents-ttl {
    font-size: 2rem;
  }
  .p-message__contents .p-message__contents-txtWrap {
    margin-top: 40px;
  }
  .p-message__contents .p-message__contents-txt {
    line-height: 2;
  }
  .p-message__contents .p-message__contents-txt:nth-child(n+2) {
    margin-top: 14px;
  }
  .p-message__contents .p-message__contents-name {
    margin-top: 40px;
    font-size: 1.1875rem;
  }
  .p-message__contents .p-message__contents-name-small {
    padding-right: 16px;
    font-size: 0.9375rem;
  }
}
@media all and ( 1320px < width ) {
  .p-message__contents .p-message__contents-img {
    bottom: 70px;
  }
}

/*  p-message__company
----------------------------------- */
.p-message__company {
  position: relative;
  padding-top: 102px;
}
@media all and ( 1008px <= width ) {
  .p-message__company {
    padding-top: 240px;
  }
}

/*  p-company
----------------------------------- */
.p-company {
  padding-top: 80px;
}
.p-company .p-company-about {
  padding-bottom: 80px;
}
.p-company .p-company-about__inner {
  width: min(100%, 1000px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-company .p-company-about__inner {
    width: min(100%, 1160px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-company .p-company-access {
  padding: 80px 0;
  background: #F4EDE7;
}
.p-company .p-company-access__inner {
  width: min(100%, 1000px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-company .p-company-access__inner {
    width: min(100%, 1160px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-company .p-company-access__contents-txt {
  margin-bottom: 40px;
}
.p-company .p-company-access__contents-map {
  line-height: 1.1;
}
.p-company .p-company-access__contents-map iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  vertical-align: bottom;
}
@media all and ( 1008px <= width ) {
  .p-company {
    padding-top: 80px;
  }
  .p-company .p-company-about {
    padding-bottom: 130px;
  }
  .p-company .p-company-about__table th {
    width: 240px;
  }
  .p-company .p-company-access {
    padding: 130px 0;
  }
  .p-company .p-company-access__head {
    margin-bottom: 30px;
  }
  .p-company .p-company-access__contents-txt {
    margin-bottom: 80px;
    text-align: center;
  }
  .p-company .p-company-access__contents-map iframe {
    aspect-ratio: 960/400;
  }
}

/*  p-privacy
----------------------------------- */
.p-privacy {
  padding: 80px 0;
}
.p-privacy .p-privacy__inner {
  width: min(100%, 1000px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-privacy .p-privacy__inner {
    width: min(100%, 1160px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-privacy .p-privacy__txt {
  padding: 24px 32px;
  background: #F4EDE7;
  border-radius: 10px;
}
.p-privacy .p-privacy__contents {
  margin-top: 60px;
}
.p-privacy .p-privacy__contents-item:nth-child(n+2) {
  margin-top: 40px;
}
.p-privacy .p-privacy__contents-ttl {
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.25rem;
}
.p-privacy .p-privacy__contents-txt {
  margin-top: 16px;
}
.p-privacy .p-privacy__contents-link {
  display: inline-block;
  text-decoration: underline;
}
.p-privacy .p-privacy__contents-link:hover, .p-privacy .p-privacy__contents-link:focus-visible {
  text-decoration: none;
}
@media all and ( 1008px <= width ) {
  .p-privacy {
    padding: 85px 0 130px;
  }
  .p-privacy .p-privacy__txt {
    padding: 32px 50px;
  }
  .p-privacy .p-privacy__contents {
    margin-top: 80px;
  }
  .p-privacy .p-privacy__contents-item:nth-child(n+2) {
    margin-top: 50px;
  }
  .p-privacy .p-privacy__contents-txt {
    margin-top: 10px;
    font-size: 1rem;
  }
}

/*  p-contact
----------------------------------- */
.p-contact {
  padding: 80px 0;
}
.p-contact .p-contact__inner {
  width: min(100%, 1000px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-contact .p-contact__inner {
    width: min(100%, 1160px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-contact .p-contact__contents {
  margin-top: 24px;
  padding: 24px 16px 16px;
  background: #F4EDE7;
  border-radius: 10px;
}
.p-contact .p-contact__contents-txt {
  margin-bottom: 24px;
  padding: 0 16px;
}
.p-contact .p-contact__contents-txt-primary {
  color: #EC6C00;
}
.p-contact .p-contact__contents-item {
  padding: 24px 16px 0;
}
.p-contact .p-contact__contents-form {
  margin-top: 40px;
}
@media all and ( 1008px <= width ) {
  .p-contact {
    padding: 80px 0 130px;
  }
  .p-contact .p-contact__txt {
    text-align: center;
  }
  .p-contact .p-contact__contents {
    margin-top: 60px;
    padding: 40px 80px;
  }
  .p-contact .p-contact__contents-txt {
    margin-bottom: 32px;
    padding: 0;
    text-align: center;
  }
  .p-contact .p-contact__contents-form {
    margin-top: 80px;
  }
}

/*  p-column
----------------------------------- */
.p-column {
  padding: 80px 0;
}
.p-column .p-column__inner {
  width: min(100%, 1320px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1320px) {
  .p-column .p-column__inner {
    width: min(100%, 1480px);
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-column .p-column__info {
  padding-top: 60px;
}
.p-column .p-column__info-head {
  display: flex;
  margin-bottom: 40px;
  align-items: flex-start;
  gap: 16px;
}
.p-column .p-column__info-head-icon {
  width: 27px;
}
.p-column .p-column__info-head-ttl {
  width: calc(100% - 43px);
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-size: 1.4375rem;
}
@media all and ( 1008px <= width ) {
  .p-column {
    padding: 70px 0 130px;
  }
  .p-column .p-column__info {
    padding-top: 130px;
  }
  .p-column .p-column__info-head {
    margin-bottom: 60px;
    align-items: center;
    gap: 24px;
  }
  .p-column .p-column__info-head-icon {
    width: 36px;
  }
  .p-column .p-column__info-head-ttl {
    width: calc(100% - 60px);
    font-size: 2.5rem;
  }
}