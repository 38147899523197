@use '../../fundation/index' as *;

/*  p-column
----------------------------------- */
.p-column{
  padding: 80px 0;
  .p-column__inner{
    @include inner;
  }
  .p-column__info{
    padding-top: 60px;
  }
  .p-column__info-head{
    display: flex;
    margin-bottom: 40px;
    align-items: flex-start;
    gap: 16px;
  }
  .p-column__info-head-icon{
    width: 27px;
  }
  .p-column__info-head-ttl{
    width: calc(100% - 43px);
    @include font_title;
    font-size: font-size(23);
  }
  @include mq (large){
    padding: 70px 0 130px;
    .p-column__info{
      padding-top: 130px;
    }
    .p-column__info-head{
      margin-bottom: 60px;
      align-items: center;
      gap: 24px;
    }
    .p-column__info-head-icon{
      width: 36px;
    }
    .p-column__info-head-ttl{
      width: calc(100% - 60px);
      font-size: font-size(40);
    }
  }
}