@use '../../fundation/index' as *;

/*  c-article01
----------------------------------- */
.c-article01{
  margin: 0 -20px;
  padding: 40px 20px 60px;
  background: $clr_bg;
  .c-article01__thumb{
    margin-bottom: 40px;
    img{
      border-radius: 10px;
    }
  }
  &__contents{
		overflow: hidden;
		> *{
			margin-top: 32px;
			&:first-child{
				margin-top: 0;
			}
		}
		h2,h3{
			clear: both;
		}
    h2{
      position: relative;
      margin-bottom: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      @include font_title;
      font-size: font-size(22);
      border-bottom: 1px solid $clr_border;
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 5px;
        height: calc(100% - 26px);
        top: 6px;
        left: 0;
        background: $clr_primary;
      }
    }
		h3{
      margin-bottom: 16px;
			padding-bottom: 20px;
      @include font_title;
      font-size: font-size(20);
      border-bottom: 1px solid $clr_border;
		}
    h4{
      @include font_title;
      font-size: font-size(18);
    }
		strong,b{
			position: relative;
			display: inline;
			color: $clr_primary;
		}
		ul,ol{
			list-style: none;
			li{
				position: relative;
        &:nth-child(n+2) {
          margin-top: 6px;
        }
			}
		}
    ul{
      li{
        padding-left: 16px;
        &::before {
          content: '';
          position: absolute;
          display: block;
          width: 6px;
          height: 2px;
          top: 15px;
          left: 0;
          background: $clr_default;
        }
      }
    }
		ol{
			counter-reset: item;
			li{
				text-indent: -20px;
				padding-left: 20px;
				&::before{
					counter-increment: item;
					content: counter(item)'. ';
          @include font_title;
				}
			}
		}
    img{
        width: auto;
        max-width: 100%;
    }
	}
  @include mq (medium){
    margin: 0;
  }
  @include mq (large){
    padding: 100px 110px;
    .c-article01__thumb{
      margin-bottom: 80px;
    }
    &__contents{
      margin: 0 auto;
      width: min(920px,100%);
      > *{
        margin-top: 40px;
        &:first-child{
          margin-top: 0;
        }
      }
      h2{
        margin-bottom: 32px;
        padding-left: 30px;
        font-size: font-size(28);
        &::before {
          width: 6px;
        }
      }
      h3{
        margin-bottom: 24px;
        font-size: font-size(24);
      }
      h4{
        font-size: font-size(20);
      }
      ul,ol{
        li{
          &:nth-child(n+2) {
            margin-top: 8px;
          }
        }
      }
      ul{
        li{
          &::before {
            width: 8px;
            height: 3px;
            top: 14px;
          }
        }
      }
      ol{
        li{
          padding-left: 22px;
        }
      }
      .aligncenter{
        display: block;
        margin: 0 auto;
        text-align: center;
        img{
          display: block;
          margin: 0 auto;
        }
      }
      .alighleft{
        float: left;
        padding: 0 40px 40px 0;
      }
      .alignright{
        float: right;
        padding: 0 0 40px 40px;
      }
    }
  }
}